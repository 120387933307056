.footer {
    
    @include xs {
        text-align: center;
    }
    &-top {
        border-top: 2px solid #262626;
        padding: 30px 0 50px;
        
        .container {
        
        }
        @include md {
            padding: 20px 0;
        }
    }
    &-row {
        @include row(10);
    }
    &-left {
        @include col(4, 10);
        
        svg, img {
            max-width: 100%;
        }
        @include lg {
            @include col(5, 10);
        }
        @include md {
            @include col(12, 10);
            margin-bottom: 30px;
        }
    }
    &-middle {
        @include col(3, 10);
        font-size: 14px;
    
        @include md {
            @include col(4, 10);
        }
        @include xs {
            @include col(12, 10);
            margin-bottom: 10px;
        }
        p {
            margin: 0 0 20px;
    
            @include sm {
                margin-bottom: 10px;
            }
        }
        a {
            color: $b;
        }
    }
    &-right {
        @include col(5, 10);
        
        @include lg {
            @include col(4, 10);
        }
        @include md {
            @include col(6, 10);
        }
        @include xs {
            @include col(12, 10);
        }
    }
    &-menu {
        @include row(10);
        
        &__item {
            @include col(6, 10);
            margin-bottom: 20px;
    
            @include lg {
                @include col(12, 10);
            }
            @include sm {
                margin-bottom: 10px;
            }
        }
    }
    &-bottom {
        background: #262626;
        color: #929292;
        font-size: 12px;
        
        @include md {
            padding: 10px 0;
        }
        a {
            color: #929292;
            
            &:hover {
                color: $w;
            }
        }
        .container {
            @include flex(sc);
            position: relative;
            
            @include md {
                flex-wrap: wrap;
            }
        }
    }
    &-col {
        min-height: 50px;
        @include flex(lc);
        
        @include md {
            @include flex(cc);
            width: 100%;
            min-height: inherit;
            margin: 5px 0;
        }
    }
    .garpix {
        @include flex(lc);
        
        svg {
            margin-left: 6px;
        }
    }
    .totop {
        position: absolute;
        width: 50px;
        height: 50px;
        background: url(../../images/arrow-up.svg) center no-repeat;
        opacity: .5;
        bottom: 0;
        left: -70px;
        
        &:hover {
            opacity: 1;
            background-color: #363636;
        }
    }
}
