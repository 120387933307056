@import "../../../styles/global/variables.scss";

.category-list {
    display: flex;
    overflow-x: auto;
    text-align: center;
    font-size: 13px;
    margin-bottom: 30px;
    &__title {
        font-size: 13px;
        color: $bd;
    }
    &__item {
        width: 110px;
        &:not(:last-child) {
            margin-right: 10px;
        }
    }
    &__img {
        width: 62px;
        height: 62px;
        max-width: initial;
    }
}

.btn-more {
    display: inline-flex !important;
}