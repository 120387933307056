.header {
    background: $w;
    transition: .3s;
    position: fixed;
    z-index: 12;
    top: 0;
    left: 0;
    width: 100%;
    @extend %grad;
    
    &-wrap {
    }
    &-top {
        background: $w;
        width: 100%;
        border-bottom: 1px solid #EEEFF0;
        
        .container {
            @include flex(sc);
            height: 70px;
            transition: .3s;
            
            .header.fixed & {
                height: 64px;
            }
            @include md {
                padding: 0 15px;
                height: 50px !important;
            }
            @include sm {
                height: 50px !important;
            }
        }
    }
    &-logo {
        @include flex(lc);

        @include md {
            width: 100px;
        }
        
        @include sm {
            width: 100px;
        }
        @include xxs {
            width: 90px;
        }
        svg, img {
            max-width: 100%;
        }
    }
    &-logom {
        width: 100%;
        display: none;
        
        @include lg {
        }
        svg {
            width: 100%;
            height: auto;
        }
    }
    &-btmwrap {
    
        @include xxl {
        }
        @include xl {
        }
        @include md {
            position: fixed;
            top: 60px;
            right: 0;
            width: 100%;
            height: calc(100% - 60px);
            z-index: -1;
            visibility: hidden;
            opacity: 0;
            transition: .2s;
            overflow: auto;
            display: block;
            
            &:before {
                background: $bd;
                top: 0;
                left: 0;
                position: absolute;
                width: 100%;
                height: 100%;
                content: '';
                opacity: .4;
            }
            .header--menushowed & {
                z-index: 30;
                visibility: visible;
                opacity: 1;
                transform: none;
            }

            .header-btm .container {
                height: auto;
            }
        }
        @include sm {
            top: 50px;
            height: calc(100% - 50px);
        }
    }
    &-btm {
        
        @include md {
    
            position: fixed;
            top: 60px;
            right: 0;
            background: $w;
            width: 280px;
            height: calc(100% - 60px);
            z-index: -1;
            visibility: hidden;
            opacity: 0;
            transform: translateX(280px);
            transition: .2s;
            overflow: auto;
            display: block;
    
            &:before {
                width: 100%;
                height: 100%;
            }
            .header--menushowed & {
                z-index: 31;
                visibility: visible;
                opacity: 1;
                transform: none;
            }
        }
        @include sm {
            top: 50px;
            height: calc(100% - 50px);
        }
        .container {
            
            @include md {
                padding: 20px;
                @include flex(lt);
                @include flexcol(n);
            }
        }
    }
    &-menu {
        @include flex(lc);
        
        @include md {
            @include flex(lt);
            @include flexcol(n);
            width: 100%;
            margin: 30px 0;
        }
        &__item {
            position: relative;
            margin-right: 15px;
            padding-right: 16px;
            
            @include md {
                margin-right: 0;
                padding-right: 0;
                margin-bottom: 20px;
                padding-bottom: 20px;
                width: 100%;
            }
            &:after {
                top: 0;
                width: 1px;
                height: 100%;
                position: absolute;
                content: '';
                right: 0;
                background: #B5CFE8;
                
                @include md {
                    width: 100%;
                    height: 1px;
                    top: 100%;
                }
            }
            &:last-child {
                margin-right: 0;
                padding-right: 0;
            }
            &:last-child:after {
                display: none;
            }
        }
        &__link {
            text-transform: uppercase;
            font-weight: 500;
            font-size: 12px;
            color: $b;
            
            @include md {
                font-size: 14px;
            }
            &:hover {
                color: $bl;
            }
        }
    }
    &-search {
        
        @include md {
            display: none;
        }
        &--mob {
            display: none;
            margin-bottom: 60px;
            
            @include md {
                display: block;
            }
        }
        &__form {
            position: relative;
        }
        &__input {
            width: 100%;
            padding-right: 34px;
            background: #F8F8F8;
            border-color: #F8F8F8;
            box-shadow: inset 1px 1px 10px rgba(255, 251, 251, 0.05);
            border-radius: 4px;
            height: 34px;
            font-size: 14px;
            
            @include md {
                height: 48px;
            }
        }
        &__btn {
            position: absolute;
            top: 0;
            right: 0;
            width: 34px;
            height: 34px;
            background: transparent url(../../images/search.svg) center no-repeat !important;
            display: block;
            min-width: 34px;
            transition: .2s;
            filter: grayscale(1);
            opacity: .6;
            padding: 0 !important;
    
            @include md {
                height: 48px;
            }
            &:hover {
                filter: grayscale(0);
                opacity: 1;
            }
        }
    }
    &-btm {
        .container {
            height: 54px;
            @include flex(ss);
    
            transition: .3s;
    
            // .header.fixed & {
            //     height: 40px;
            // }
        }
    }
    &-right {
        @include flex(rs);
        
        @include md {
            flex-wrap: wrap;
            justify-content: flex-start;
        }
    }
    &-logreg {
    
        @include flex(lc);
    
        @include md {
            flex-wrap: wrap;
            width: 100%;
        }
        &__item {
            position: relative;
            margin-right: 15px;
            padding-right: 16px;
            
            @include md {
                margin-right: 0;
                padding-right: 0;
                margin-bottom: 15px;
                padding-bottom: 15px;
                width: 100%;
            }
            &:after {
                top: 0;
                width: 1px;
                height: 100%;
                position: absolute;
                content: '';
                right: 0;
                background: #B5CFE8;
                
                @include md {
                    width: 100%;
                    height: 1px;
                    top: 100%;
                }
            }
            &:last-child {
                margin-right: 0;
                padding-right: 0;
            }
            &:last-child:after {
                display: none;
            }
        }
        &__link {
            font-weight: bold;
            color: $bl;
            font-size: 14px;
            
            @include md {
                font-size: 16px;
            }
        }
    }
    &-mobmenubtn {
        display: none;
    
        width: 32px;
        height: 28px;
        border: 0;
        position: relative;
        background: none;
        cursor: pointer;
        margin-top: 0px;
        margin-left: auto;
    
        @include md {
            display: block;
        }
        div {
            position: absolute;
            left: 0;
            background: $bl;
            width: 100%;
            height: 2px;
            transition: transform .2s ease, top .2s ease .2s, bottom .2s ease .2s, opacity .2s ease .2s;
        
            &:nth-child(1){
                top: 4px;
                width: 80%;
                left: 15%;
            }
            &:nth-child(2){
                top: calc(50% - 1px);
                width: 80%;
                left: 15%;
            }
            &:nth-child(3){
                bottom: 4px;
                width: 80%;
                left: 15%;
            }
        }
        .header--menushowed & {
            div{
                transition: transform .2s ease .2s, top .2s ease, bottom .2s ease, opacity .2s ease;
                
                left: 5px;
                
                &:nth-child(1){
                    transform: rotate(45deg);
                    top: calc(50% - 2px);
                }
                &:nth-child(2){
                    opacity: 0;
                }
                &:nth-child(3){
                    transform: rotate(-45deg);
                    bottom: calc(50% - 0px);
                }
            }
        }
    }
    &-cart {
        min-width: 190px;
        height: 100%;
        border-left: 1px solid #EEEFF0;
        border-right: 1px solid #EEEFF0;
        @include flex(cc);
        font-size: 14px;
        position: relative;
        
        @include md {
            height: 50px;
            border: 1px solid #EEEFF0;
            margin-bottom: 20px;
        }
        svg {
            margin-right: 10px;
            
            path {
                transition: .2s;
            }
        }
        &:hover svg path {
            fill: $bl;
        }
        &__text {
            position: relative;
            transition: .2s;
            color: $b;
        }
        &:hover &__text {
            color: $bl;
        }
        &__count {
            position: absolute;
            top: -13px;
            left: -22px;
            background: $rd;
            font-size: 12px;
            font-weight: 500;
            color: $w;
            border-radius: 100%;
            @include flex(cc);
            min-width: 18px;
            height: 18px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        }
    }
    &-cabinet {
        min-width: 190px;
        height: 100%;
        border-left: 1px solid #EEEFF0;
        border-right: 1px solid #EEEFF0;
        @include flex(cc);
        font-size: 14px;
        position: relative;
        margin-left: -1px;
    
        @include md {
            height: 50px;
            border: 1px solid #EEEFF0;
            margin-bottom: 20px;
            margin-left: 0;
        }
        svg {
            margin-right: 10px;
            
            path {
                transition: .2s;
            }
        }
        &:hover svg path {
            fill: $bl;
        }
        &__text {
            position: relative;
            transition: .2s;
            color: $b;
        }
        &:hover &__text {
            color: $bl;
        }
    }
}