$w: #fff;
$b: #13293d;
$w25: rgba(255, 255, 255, 0.25);
$w30: rgba(255, 255, 255, 0.3);
$w50: rgba(255, 255, 255, 0.5);
$b3: #f5f8ff;
$gt: #838aa2;
$gr: #c4c8cb;
$gg: #b5cfe8;
$bl: #3873ee;
$bb: #0a369d;
$bd: #16324f;
$bg: #f0f4f9;
$bg2: #f6f7f8;
$rd: #f61459;
$brd: #dae7f4;
$b80: #333333;
$b50: #808080;
$b40: #999999;
$b35:#9CA9B9;
$b30: #b3b3b3;
$b20: #cccccc;
$textdel: #8A98A7;
$text: #546682;
$texti: #8998af;
$gre: #37ed80;

$xxxs-width: 321.98px;
$xxs-width: 359.98px;
$xs-width: 567.98px;
$sm-width: 767.98px;
$md-width: 991.98px;
$lg-width: 1289.98px;
$xl-width: 1559.98px;
$xxl-width: 1749.98px;

@mixin xxl {
  @media screen and (max-width: #{$xxl-width}) {
    @content;
  }
}

@mixin xl {
  @media screen and (max-width: #{$xl-width}) {
    @content;
  }
}

@mixin lg {
  @media screen and (max-width: #{$lg-width}) {
    @content;
  }
}

@mixin md {
  @media screen and (max-width: #{$md-width}) {
    @content;
  }
}

@mixin sm {
  @media screen and (max-width: #{$sm-width}) {
    @content;
  }
}

@mixin xs {
  @media screen and (max-width: #{$xs-width}) {
    @content;
  }
}

@mixin xxs {
  @media screen and (max-width: #{$xxs-width}) {
    @content;
  }
}

@mixin xxxs {
  @media screen and (max-width: #{$xxs-width}) {
    @content;
  }
}

@mixin row($mr) {
  display: flex;
  flex-wrap: wrap;
  margin-left: -#{$mr}px;
  margin-right: -#{$mr}px;
}

@mixin row-flex() {
  display: flex;
  flex-wrap: wrap;
}

@mixin col($colsize, $mrg) {
  width: calc(100% / 12 * #{$colsize} - #{$mrg * 2}px - 0.1px);
  margin-left: #{$mrg}px;
  margin-right: #{$mrg}px;
  word-wrap: break-word;
}

@mixin coloff($coloffset, $mrg2) {
  margin-left: calc(100% / 12 * #{$coloffset} + #{$mrg2}px);
}

@mixin h($s) {
  @if $s == 1 {
    @extend %heading;
    font-size: 42px;

    @include xxl {
    }
    @include xl {
    }
    @include lg {
      font-size: 38px;
    }
    @include md {
      font-size: 32px;
    }
    @include sm {
      font-size: 28px;
    }
  } @else if $s == 2 {
    @extend %heading;
    font-size: 32px;

    @include xxl {
    }
    @include xl {
    }
    @include sm {
      font-size: 24px;
    }
  } @else if $s == 3 {
    @extend %heading;
    font-size: 26px;

    @include xxl {
    }
    @include lg {
    }
    @include sm {
      font-size: 22px;
    }
  } @else if $s == 4 {
    @extend %heading;
    font-size: 16px;
    font-weight: 500;

    @include xxl {
    }
    @include sm {
    }
  } @else if $s == 5 {
    @extend %heading;
    font-size: 14px;

    @include xl {
    }
    @include sm {
    }
  } @else {
  }
}

@mixin flex($ff) {
  display: flex;

  @if $ff == lt {
    justify-content: flex-start;
    align-items: flex-start;
  } @else if $ff == lc {
    justify-content: flex-start;
    align-items: center;
  } @else if $ff == lb {
    justify-content: flex-start;
    align-items: flex-end;
  } @else if $ff == ct {
    justify-content: center;
    align-items: flex-start;
  } @else if $ff == cc {
    justify-content: center;
    align-items: center;
  } @else if $ff == cb {
    justify-content: center;
    align-items: flex-end;
  } @else if $ff == rt {
    justify-content: flex-end;
    align-items: flex-start;
  } @else if $ff == rc {
    justify-content: flex-end;
    align-items: center;
  } @else if $ff == rb {
    justify-content: flex-end;
    align-items: flex-end;
  } @else if $ff == st {
    justify-content: space-between;
    align-items: flex-start;
  } @else if $ff == sc {
    justify-content: space-between;
    align-items: center;
  } @else if $ff == sb {
    justify-content: space-between;
    align-items: flex-end;
  } @else if $ff == ls {
    justify-content: flex-start;
    align-items: stretch;
  } @else if $ff == cs {
    justify-content: center;
    align-items: stretch;
  } @else if $ff == rs {
    justify-content: flex-end;
    align-items: stretch;
  } @else if $ff == ss {
    justify-content: space-between;
    align-items: stretch;
  } @else if $ff == sn {
    justify-content: space-between;
    align-items: baseline;
  } @else {
  }
}

@mixin flexcol($fw) {
  @if $fw == w {
    flex-flow: column wrap;
  } @else if $fw == n {
    flex-flow: column nowrap;
  }
}
