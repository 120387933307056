*,
*::after,
*::before {
  box-sizing: border-box;
}
@font-face {
  font-family: "Ubuntu";
  src: url("../../fonts/subset-Ubuntu-Medium.woff2") format("woff2"),
    url("../../fonts/subset-Ubuntu-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Ubuntu";
  src: url("../../fonts/subset-Ubuntu-Light.woff2") format("woff2"),
    url("../../fonts/subset-Ubuntu-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Ubuntu";
  src: url("../../fonts/subset-Ubuntu-Bold.woff2") format("woff2"),
    url("../../fonts/subset-Ubuntu-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Ubuntu";
  src: url("../../fonts/subset-Ubuntu-Regular.woff2") format("woff2"),
    url("../../fonts/subset-Ubuntu-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  min-width: 320px;
  font-family: "Ubuntu", -apple-system, system-ui, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: $b;
  background: $w;
  overscroll-behavior: none;

  @include xl {
  }
  @include sm {
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  cursor: pointer;
  color: $bl;
  transition: 0.2s;
  outline: none !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
  text-decoration: none;
}

a:hover {
  color: $bd;
  text-decoration: none;
}

a.disabled {
}

a.underline {
  text-decoration: underline;
}

a.underline:hover {
  text-decoration: none;
}

strong {
  font-weight: 600;
}

.container {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 30px;

  @include xxl {
  }
  @include xl {
  }
  @include lg {
  }
  @include sm {
    padding: 0 15px;
  }
}

/*.root {
    transition: .3s;
    opacity: 0;

    &.doc-ready {
        opacity: 1;
    }
}*/
%heading {
  font-weight: bold;
  line-height: 1.3;
}
%p24 {
  font-size: 24px;
  font-weight: 300;

  @include xxl {
    font-size: 18px;
  }
  @include sm {
    font-size: 16px;
  }
}
%p20 {
  font-size: 20px;
  font-weight: 300;

  @include xxl {
  }
  @include sm {
  }
}
%p18 {
  font-size: 18px;

  @include sm {
    font-size: 16px;
  }
}
%p14 {
  font-size: 14px;

  @include sm {
    font-size: 14px;
  }
}
%scroll {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;

    @include md {
      width: 3px;
      height: 3px;
    }
  }
  &::-webkit-scrollbar-track {
    background: #eee;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
  }
}
%nomargin {
  margin-top: -32px;
  margin-bottom: -64px;

  @include sm {
    margin-bottom: -32px;
  }
}
%grad {
  box-shadow: 12px 12px 24px rgba(35, 111, 153, 0.04),
    -12px -12px 24px rgba(35, 111, 153, 0.04);
}

h1 {
  @include h(1);
  margin: 0 0 30px;
}
h2 {
  @include h(2);
  margin: 0 0 30px;
}
h3 {
  @include h(3);
  margin: 0 0 20px;
}
h4 {
  @include h(4);
  margin: 0 0 30px;
}
h5 {
  @include h(5);
  margin: 0 0 10px;
}
h6 {
  @include h(6);
  margin: 0;
}
p + h1,
p + h2,
p + h3,
p + h4 {
  margin-top: 60px;
}
.hidden {
  visibility: hidden;
}

.none {
  display: none;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;

  tr:nth-child(even) {
    background: #f8fafc;
  }
  th {
    color: $b;
    padding: 15px 15px;
    border: 0;
    font-weight: normal;
  }
  td {
    padding: 15px 15px;
    border: 0;
    border-bottom: 0;
  }
}
.table {
  min-width: 500px;

  @include sm {
    font-size: 14px;
  }
  &-list__wrap {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  &-wrap {
    overflow: auto;
    display: block;
    min-height: 450px;
    &__filter {
      max-width: 320px;
      width: 100%;
      margin-bottom: 15px;
    }

    @include sm {
      margin-left: -15px;
      margin-right: -15px;
    }
  }
  th {
    color: $b;
    opacity: 0.6;
    padding: 15px 15px;
    font-weight: normal;
    border-bottom: 0;

    p {
      margin: 0;
    }
  }
  td:not(.my-shops__name-shop) {
    padding: 15px 15px;
  }
  td {
    border-bottom: 0;

    p {
      margin: 0;
    }
  }
  & td.table__tadd {
    padding: 0;
  }
}

.tabs {
  width: 100%;

  &__wrap {
    @include md {
      position: relative;
      overflow-y: hidden;
      overflow-x: scroll;
      margin: 0 -30px;
    }
    @include sm {
      margin: 0 -15px;
    }
  }
  &__list {
    padding: 0;
    margin: 0;
    @include flex(lc);
    width: 100%;

    @include md {
      display: inline-block;
      white-space: nowrap;
    }
    .btn {
      margin-left: auto;
      margin-right: 10px;
    }
  }
  &__item {
    list-style: none;
    display: inline-block;
    margin-right: 5px;

    @include md {
      width: auto;
    }
    &:last-child {
      margin-right: 0;

      @include md {
        margin-right: 30px;
      }
      @include sm {
        margin-right: 15px;
      }
    }
    &:first-child {
      @include md {
        margin-left: 30px;
      }
      @include sm {
        margin-left: 15px;
      }
    }
    &_help {
      margin-left: auto;
    }
  }
  &__link {
    display: block;
    color: $b;
    padding: 10px 40px;
    position: relative;
    white-space: normal;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    color: $gt;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 4px 4px 0 0;

    @include xl {
    }
    @include lg {
      min-width: inherit;
    }

    &:hover {
      color: $bl;
      text-decoration: none;
    }
    &.active {
      color: $bl;
      background: $w;
    }
    &_help {
      position: relative;
      background: transparent;
      color: $bl;
      &::after {
        position: absolute;
        content: "?";
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        display: inline-block;
        font-size: 1em;
        line-height: 1;
        font-weight: bold;
        width: 20px;
        height: 20px;
        border: 2px solid $bl;
        border-radius: 20px;
        text-align: center;
        vertical-align: middle;
      }
    }
  }
  &__content {
    background: $w;
    overflow: hidden;
    border-top: 0;

    @include md {
      margin-left: -30px;
      padding-left: 30px;
      margin-right: -30px;
      padding-right: 30px;
    }
    @include sm {
      margin-left: -15px;
      padding-left: 15px;
      margin-right: -15px;
      padding-right: 15px;
    }
  }
  &__content > &__pane {
    display: block;
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: opacity 0.2s;

    @include md {
      margin-left: -30px;
      padding-left: 30px;
      margin-right: -30px;
      padding-right: 30px;
    }
    @include sm {
      margin-left: -15px;
      padding-left: 15px;
      margin-right: -15px;
      padding-right: 15px;
    }
  }
  &__content > &__pane.active {
    height: auto;
    opacity: 1;
  }
}

.btabs {
  width: 100%;

  &__wrap {
    @include md {
      position: relative;
      overflow-y: hidden;
      overflow-x: scroll;
      margin: 0 -30px;
    }
    @include sm {
      margin: 0 -15px;
    }
  }
  &__list {
    padding: 0;
    margin: 0;
    @include flex(lc);
    width: 100%;
    border-bottom: 1px solid $gg;
    margin-bottom: 40px;

    @include md {
      display: inline-block;
      white-space: nowrap;
      margin-bottom: 20px;
      border-bottom: 0;
    }
    .btn {
      margin-left: auto;
      margin-right: 10px;
    }
  }
  &__item {
    list-style: none;
    display: inline-block;

    @include md {
      width: auto;
      border-bottom: 1px solid $gg;
    }
    &:last-child {
      margin-right: 0;

      @include md {
        margin-right: 30px;
      }
      @include sm {
        margin-right: 15px;
      }
    }
    &:first-child {
      @include md {
        margin-left: 30px;
      }
      @include sm {
        margin-left: 15px;
      }
    }
  }
  &__link {
    display: block;
    color: $b;
    padding: 10px 30px;
    position: relative;
    white-space: normal;
    text-align: center;
    text-decoration: none;
    @include h(4);
    color: $gt;
    margin-right: 30px;
    border-bottom: 3px solid transparent;
    text-transform: uppercase;

    @include xl {
    }
    @include lg {
      min-width: inherit;
    }
    @include sm {
      padding: 10px 5px;
    }
    &:hover {
      color: $bl;
      text-decoration: none;
    }
    &.active {
      color: $b;
      border-color: $bb;
    }
  }
  &__content {
    background: $w;
    overflow: hidden;
    border-top: 0;

    @include md {
      margin-left: -30px;
      padding-left: 30px;
      margin-right: -30px;
      padding-right: 30px;
    }
    @include sm {
      margin-left: -15px;
      padding-left: 15px;
      margin-right: -15px;
      padding-right: 15px;
    }
  }
  &__content > &__pane {
    display: block;
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: opacity 0.2s;

    @include md {
      margin-left: -30px;
      padding-left: 30px;
      margin-right: -30px;
      padding-right: 30px;
    }
    @include sm {
      margin-left: -15px;
      padding-left: 15px;
      margin-right: -15px;
      padding-right: 15px;
    }
  }
  &__content > &__pane.active {
    height: auto;
    opacity: 1;
  }
}

.row {
  @include row(12);
}
.col-1 {
  @include col(1, 12);

  @include sm {
    @include col(11, 12);
  }
}
.col-2 {
  @include col(2, 12);

  @include sm {
    @include col(11, 12);
  }
}
.col-3 {
  @include col(3, 12);

  @include sm {
    @include col(11, 12);
  }
}
.col-4 {
  @include col(4, 12);

  @include sm {
    @include col(11, 12);
  }
}
.col-5 {
  @include col(5, 12);

  @include sm {
    @include col(11, 12);
  }
}
.col-6 {
  @include col(6, 12);

  @include sm {
    @include col(11, 12);
  }
}
.col-7 {
  @include col(7, 12);

  @include sm {
    @include col(11, 12);
  }
}
.col-8 {
  @include col(8, 12);

  @include sm {
    @include col(11, 12);
  }
}
.col-9 {
  @include col(9, 12);

  @include sm {
    @include col(11, 12);
  }
}
.col-10 {
  @include col(10, 12);

  @include sm {
    @include col(11, 12);
  }
}
.col-11 {
  @include col(11, 12);

  @include sm {
    @include col(11, 12);
  }
}
.col-12 {
  @include col(12, 12);
}
