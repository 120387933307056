@import "../../../styles/global/variables.scss";
.head-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    &__more-link {
        display: block;
        text-decoration: underline;
    }
}

.shop-block {
    background-color: $b3;
    border-radius: 20px;
    padding: 25px 16px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    &__title {
        margin-bottom: 5px;
        word-break: break-word;
        color: $bd;
    }
    &__img {
        width: 82px;
        height: 82px;
        border-radius: 100%;
        margin-right: 16px;
        object-fit: cover;
        max-width: none;
    }
    &__cat-name {
        margin: 0 0 5px 0;
        color: $b35;
    }
}

.rating {
    display: flex;
    align-items: center;
    &__info {
        margin: 0 0 0 12px;
        color: $b35;
    }
}