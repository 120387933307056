/**
 * Swiper 3.3.1
 * Most modern mobile touch slider and framework with hardware accelerated transitions
 *
 * http://www.idangero.us/swiper/
 *
 * Copyright 2016, Vladimir Kharlampidi
 * The iDangero.us
 * http://www.idangero.us/
 *
 * Licensed under MIT
 *
 * Released on: February 7, 2016
 */
.swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  /* Fix of Webkit flickering */
  z-index: 1;
}
.swiper-container-no-flexbox .swiper-slide {
  float: left;
}
.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.swiper-container-android .swiper-slide,
.swiper-wrapper {
  -webkit-transform: translate3d(0px, 0, 0);
  -moz-transform: translate3d(0px, 0, 0);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0);
}
.swiper-container-multirow > .swiper-wrapper {
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto;
}
.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex: 0 0 auto;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
}
/* Auto Height */
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto;
}
.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-transition-property: -webkit-transform, height;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform, height;
}
/* a11y */
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}
/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}
.swiper-wp8-vertical {
  -ms-touch-action: pan-x;
  touch-action: pan-x;
}
/* Arrows */
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  -moz-background-size: 27px 44px;
  -webkit-background-size: 27px 44px;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat;
  outline: none !important;
}
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  left: 10px;
  right: auto;
}
.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto;
}
.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}
/* Pagination Styles */
.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: 300ms;
  -moz-transition: 300ms;
  -o-transition: 300ms;
  transition: 300ms;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10;
}
.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}
/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 10px;
  left: 0;
  width: 100%;
}
/* Bullets */
.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2;
}
button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}
.swiper-pagination-white .swiper-pagination-bullet {
  background: #fff;
}
.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff;
}
.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff;
}
.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000;
}
.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0px, -50%, 0);
  -moz-transform: translate3d(0px, -50%, 0);
  -o-transform: translate(0px, -50%);
  -ms-transform: translate3d(0px, -50%, 0);
  transform: translate3d(0px, -50%, 0);
}
.swiper-container-vertical
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 5px 0;
  display: block;
}
.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 5px;
}
/* Progress */
.swiper-pagination-progress {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
}
.swiper-pagination-progress .swiper-pagination-progressbar {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left top;
}
.swiper-container-rtl
  .swiper-pagination-progress
  .swiper-pagination-progressbar {
  -webkit-transform-origin: right top;
  -moz-transform-origin: right top;
  -ms-transform-origin: right top;
  -o-transform-origin: right top;
  transform-origin: right top;
}
.swiper-container-horizontal > .swiper-pagination-progress {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
}
.swiper-container-vertical > .swiper-pagination-progress {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}
.swiper-pagination-progress.swiper-pagination-white {
  background: rgba(255, 255, 255, 0.5);
}
.swiper-pagination-progress.swiper-pagination-white
  .swiper-pagination-progressbar {
  background: #fff;
}
.swiper-pagination-progress.swiper-pagination-black
  .swiper-pagination-progressbar {
  background: #000;
}
/* 3D Container */
.swiper-container-3d {
  -webkit-perspective: 1200px;
  -moz-perspective: 1200px;
  -o-perspective: 1200px;
  perspective: 1200px;
}
.swiper-container-3d .swiper-wrapper,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-cube-shadow {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}
.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(0, 0, 0, 0.5)),
    to(rgba(0, 0, 0, 0))
  );
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(
    right,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(
    right,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(
    right,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  /* Firefox 16+, IE10, Opera 12.50+ */
}
.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(rgba(0, 0, 0, 0.5)),
    to(rgba(0, 0, 0, 0))
  );
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(
    left,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(
    left,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  /* Firefox 16+, IE10, Opera 12.50+ */
}
.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0.5)),
    to(rgba(0, 0, 0, 0))
  );
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(
    bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(
    bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(
    bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  /* Firefox 16+, IE10, Opera 12.50+ */
}
.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(0, 0, 0, 0.5)),
    to(rgba(0, 0, 0, 0))
  );
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  /* Firefox 16+, IE10, Opera 12.50+ */
}
/* Coverflow */
.swiper-container-coverflow .swiper-wrapper,
.swiper-container-flip .swiper-wrapper {
  /* Windows 8 IE 10 fix */
  -ms-perspective: 1200px;
}
/* Cube + Flip */
.swiper-container-cube,
.swiper-container-flip {
  overflow: visible;
}
.swiper-container-cube .swiper-slide,
.swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
}
.swiper-container-cube .swiper-slide .swiper-slide,
.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none;
}
.swiper-container-cube .swiper-slide-active,
.swiper-container-flip .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}
.swiper-container-cube .swiper-slide-shadow-top,
.swiper-container-flip .swiper-slide-shadow-top,
.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right,
.swiper-container-flip .swiper-slide-shadow-right {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}
/* Cube */
.swiper-container-cube .swiper-slide {
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}
.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  -moz-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0;
}
.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-prev,
.swiper-container-cube .swiper-slide-next + .swiper-slide {
  pointer-events: auto;
  visibility: visible;
}
.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0;
}
/* Fade */
.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
}
.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}
.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}
/* Scrollbar */
.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1);
}
.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%;
}
.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%;
}
.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0;
}
.swiper-scrollbar-cursor-drag {
  cursor: move;
}
/* Preloader */
.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -moz-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  -moz-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite;
}
.swiper-lazy-preloader:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  -webkit-background-size: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
}
.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}
@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg);
  }
}

// body.compensate-for-scrollbar {
//     overflow: hidden;
// }

// .fancybox-active {
//     height: auto;
// }

// .fancybox-is-hidden {
//     left: -9999px;
//     margin: 0;
//     position: absolute !important;
//     top: -9999px;
//     visibility: hidden;
// }

// .fancybox-container {
//     -webkit-backface-visibility: hidden;
//     height: 100%;
//     left: 0;
//     outline: none;
//     position: fixed;
//     -webkit-tap-highlight-color: transparent;
//     top: 0;
//     -ms-touch-action: manipulation;
//     touch-action: manipulation;
//     transform: translateZ(0);
//     width: 100%;
//     z-index: 109946;
// }

// .fancybox-container * {
//     box-sizing: border-box;
// }

// .fancybox-outer,
// .fancybox-inner,
// .fancybox-bg,
// .fancybox-stage {
//     bottom: 0;
//     left: 0;
//     position: absolute;
//     right: 0;
//     top: 0;
// }

// .fancybox-outer {
//     -webkit-overflow-scrolling: touch;
//     overflow-y: auto;
// }

// .fancybox-bg {
//     background: $bd;
//     opacity: 0;
//     transition-duration: inherit;
//     transition-property: opacity;
//     transition-timing-function: cubic-bezier(.47, 0, .74, .71);
// }

// .fancybox-is-open .fancybox-bg {
//     opacity: .64;
//     transition-timing-function: cubic-bezier(.22, .61, .36, 1);
// }

// .fancybox-infobar,
// .fancybox-toolbar,
// .fancybox-caption,
// .fancybox-navigation .fancybox-button {
//     direction: ltr;
//     opacity: 0;
//     position: absolute;
//     transition: opacity .25s ease, visibility 0s ease .25s;
//     visibility: hidden;
//     z-index: 99997;
// }

// .fancybox-show-infobar .fancybox-infobar,
// .fancybox-show-toolbar .fancybox-toolbar,
// .fancybox-show-caption .fancybox-caption,
// .fancybox-show-nav .fancybox-navigation .fancybox-button {
//     opacity: 1;
//     transition: opacity .25s ease 0s, visibility 0s ease 0s;
//     visibility: visible;
// }

// .fancybox-infobar {
//     color: #ccc;
//     font-size: 13px;
//     -webkit-font-smoothing: subpixel-antialiased;
//     height: 44px;
//     left: 0;
//     line-height: 44px;
//     min-width: 44px;
//     mix-blend-mode: difference;
//     padding: 0 10px;
//     pointer-events: none;
//     top: 0;
//     -webkit-touch-callout: none;
//     -webkit-user-select: none;
//     -moz-user-select: none;
//     -ms-user-select: none;
//     user-select: none;
// }

// .fancybox-toolbar {
//     right: 0;
//     top: 0;
// }

// .fancybox-stage {
//     direction: ltr;
//     overflow: visible;
//     transform: translateZ(0);
//     z-index: 99994;
// }

// .fancybox-is-open .fancybox-stage {
//     overflow: hidden;
// }

// .fancybox-slide {
//     -webkit-backface-visibility: hidden;
//     /* Using without prefix would break IE11 */
//     display: none;
//     height: 100%;
//     left: 0;
//     outline: none;
//     overflow: auto;
//     -webkit-overflow-scrolling: touch;
//     padding: 44px;
//     position: absolute;
//     text-align: center;
//     top: 0;
//     transition-property: transform, opacity;
//     white-space: normal;
//     width: 100%;
//     z-index: 99994;
// }

// .fancybox-slide::before {
//     content: '';
//     display: inline-block;
//     font-size: 0;
//     height: 100%;
//     vertical-align: middle;
//     width: 0;
// }

// .fancybox-is-sliding .fancybox-slide,
// .fancybox-slide--previous,
// .fancybox-slide--current,
// .fancybox-slide--next {
//     display: block;
// }

// .fancybox-slide--image {
//     overflow: hidden;
//     padding: 44px 0;
// }

// .fancybox-slide--image::before {
//     display: none;
// }

// .fancybox-slide--html {
//     padding: 6px;
// }

// .fancybox-content {
//     background: #fff;
//     display: inline-block;
//     margin: 0;
//     max-width: 100%;
//     overflow: auto;
//     -webkit-overflow-scrolling: touch;
//     padding: 44px;
//     position: relative;
//     text-align: left;
//     vertical-align: middle;
// }

// .fancybox-slide--image .fancybox-content {
//     animation-timing-function: cubic-bezier(.5, 0, .14, 1);
//     -webkit-backface-visibility: hidden;
//     background: transparent;
//     background-repeat: no-repeat;
//     background-size: 100% 100%;
//     left: 0;
//     max-width: none;
//     overflow: visible;
//     padding: 0;
//     position: absolute;
//     top: 0;
//     -ms-transform-origin: top left;
//     transform-origin: top left;
//     transition-property: transform, opacity;
//     -webkit-user-select: none;
//     -moz-user-select: none;
//     -ms-user-select: none;
//     user-select: none;
//     z-index: 99995;
// }

// .fancybox-can-zoomOut .fancybox-content {
//     cursor: zoom-out;
// }

// .fancybox-can-zoomIn .fancybox-content {
//     cursor: zoom-in;
// }

// .fancybox-can-swipe .fancybox-content,
// .fancybox-can-pan .fancybox-content {
//     cursor: -webkit-grab;
//     cursor: grab;
// }

// .fancybox-is-grabbing .fancybox-content {
//     cursor: -webkit-grabbing;
//     cursor: grabbing;
// }

// .fancybox-container [data-selectable='true'] {
//     cursor: text;
// }

// .fancybox-image,
// .fancybox-spaceball {
//     background: transparent;
//     border: 0;
//     height: 100%;
//     left: 0;
//     margin: 0;
//     max-height: none;
//     max-width: none;
//     padding: 0;
//     position: absolute;
//     top: 0;
//     -webkit-user-select: none;
//     -moz-user-select: none;
//     -ms-user-select: none;
//     user-select: none;
//     width: 100%;
// }

// .fancybox-spaceball {
//     z-index: 1;
// }

// .fancybox-slide--video .fancybox-content,
// .fancybox-slide--map .fancybox-content,
// .fancybox-slide--pdf .fancybox-content,
// .fancybox-slide--iframe .fancybox-content {
//     height: 100%;
//     overflow: visible;
//     padding: 0;
//     width: 100%;
// }

// .fancybox-slide--video .fancybox-content {
//     background: #000;
// }

// .fancybox-slide--map .fancybox-content {
//     background: #e5e3df;
// }

// .fancybox-slide--iframe .fancybox-content {
//     background: #fff;
// }

// .fancybox-video,
// .fancybox-iframe {
//     background: transparent;
//     border: 0;
//     display: block;
//     height: 100%;
//     margin: 0;
//     overflow: hidden;
//     padding: 0;
//     width: 100%;
// }

// /* Fix iOS */
// .fancybox-iframe {
//     left: 0;
//     position: absolute;
//     top: 0;
// }

// .fancybox-error {
//     background: #fff;
//     cursor: default;
//     max-width: 400px;
//     padding: 40px;
//     width: 100%;
// }

// .fancybox-error p {
//     color: #444;
//     font-size: 16px;
//     line-height: 20px;
//     margin: 0;
//     padding: 0;
// }

// /* Buttons */

// .fancybox-button {
//     background: rgba(30, 30, 30, .6);
//     border: 0;
//     border-radius: 0;
//     box-shadow: none;
//     cursor: pointer;
//     display: inline-block;
//     height: 44px;
//     margin: 0;
//     padding: 10px;
//     position: relative;
//     transition: color .2s;
//     vertical-align: top;
//     visibility: inherit;
//     width: 44px;
// }

// .fancybox-button,
// .fancybox-button:visited,
// .fancybox-button:link {
//     color: #ccc;
// }

// .fancybox-button:hover {
//     color: #fff;
// }

// .fancybox-button:focus {
//     outline: none;
// }

// .fancybox-button.fancybox-focus {
//     outline: 1px dotted;
// }

// .fancybox-button[disabled],
// .fancybox-button[disabled]:hover {
//     color: #888;
//     cursor: default;
//     outline: none;
// }

// /* Fix IE11 */
// .fancybox-button div {
//     height: 100%;
// }

// .fancybox-button svg {
//     display: block;
//     height: 100%;
//     overflow: visible;
//     position: relative;
//     width: 100%;
// }

// .fancybox-button svg path {
//     fill: currentColor;
//     stroke-width: 0;
// }

// .fancybox-button--play svg:nth-child(2),
// .fancybox-button--fsenter svg:nth-child(2) {
//     display: none;
// }

// .fancybox-button--pause svg:nth-child(1),
// .fancybox-button--fsexit svg:nth-child(1) {
//     display: none;
// }

// .fancybox-progress {
//     background: #ff5268;
//     height: 2px;
//     left: 0;
//     position: absolute;
//     right: 0;
//     top: 0;
//     -ms-transform: scaleX(0);
//     transform: scaleX(0);
//     -ms-transform-origin: 0;
//     transform-origin: 0;
//     transition-property: transform;
//     transition-timing-function: linear;
//     z-index: 99998;
// }

// /* Close button on the top right corner of html content */

// .fancybox-close-small {
//     background: transparent;
//     border: 0;
//     border-radius: 0;
//     color: #ccc;
//     cursor: pointer;
//     opacity: .8;
//     padding: 8px;
//     position: absolute;
//     right: -12px;
//     top: -44px;
//     z-index: 401;
// }

// .fancybox-close-small:hover {
//     color: #fff;
//     opacity: 1;
// }

// .fancybox-slide--html .fancybox-close-small {
//     color: currentColor;
//     padding: 10px;
//     right: 0;
//     top: 0;
// }

// .fancybox-slide--image.fancybox-is-scaling .fancybox-content {
//     overflow: hidden;
// }

// .fancybox-is-scaling .fancybox-close-small,
// .fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
//     display: none;
// }

// /* Navigation arrows */

// .fancybox-navigation .fancybox-button {
//     background-clip: content-box;
//     height: 100px;
//     opacity: 0;
//     position: absolute;
//     top: calc(50% - 50px);
//     width: 70px;
// }

// .fancybox-navigation .fancybox-button div {
//     padding: 7px;
// }

// .fancybox-navigation .fancybox-button--arrow_left {
//     left: 0;
//     left: env(safe-area-inset-left);
//     padding: 31px 26px 31px 6px;
// }

// .fancybox-navigation .fancybox-button--arrow_right {
//     padding: 31px 6px 31px 26px;
//     right: 0;
//     right: env(safe-area-inset-right);
// }

// /* Caption */

// .fancybox-caption {
//     background: linear-gradient(to top,
//         rgba(0, 0, 0, .85) 0%,
//         rgba(0, 0, 0, .3) 50%,
//         rgba(0, 0, 0, .15) 65%,
//         rgba(0, 0, 0, .075) 75.5%,
//         rgba(0, 0, 0, .037) 82.85%,
//         rgba(0, 0, 0, .019) 88%,
//         rgba(0, 0, 0, 0) 100%);
//     bottom: 0;
//     color: #eee;
//     font-size: 14px;
//     font-weight: 400;
//     left: 0;
//     line-height: 1.5;
//     padding: 75px 44px 25px 44px;
//     pointer-events: none;
//     right: 0;
//     text-align: center;
//     z-index: 99996;
// }

// .fancybox-caption--separate {
//     margin-top: -50px;
// }

// .fancybox-caption__body {
//     max-height: 50vh;
//     overflow: auto;
//     pointer-events: all;
// }

// .fancybox-caption a,
// .fancybox-caption a:link,
// .fancybox-caption a:visited {
//     color: #ccc;
//     text-decoration: none;
// }

// .fancybox-caption a:hover {
//     color: #fff;
//     text-decoration: underline;
// }

// /* Loading indicator */

// .fancybox-loading {
//     animation: fancybox-rotate 1s linear infinite;
//     background: transparent;
//     border: 4px solid #888;
//     border-bottom-color: #fff;
//     border-radius: 50%;
//     height: 50px;
//     left: 50%;
//     margin: -25px 0 0 -25px;
//     opacity: .7;
//     padding: 0;
//     position: absolute;
//     top: 50%;
//     width: 50px;
//     z-index: 99999;
// }

// @keyframes fancybox-rotate {
//     100% {
//         transform: rotate(360deg);
//     }
// }

// /* Transition effects */

// .fancybox-animated {
//     transition-timing-function: cubic-bezier(0, 0, .25, 1);
// }

// /* transitionEffect: slide */

// .fancybox-fx-slide.fancybox-slide--previous {
//     opacity: 0;
//     transform: translate3d(-100%, 0, 0);
// }

// .fancybox-fx-slide.fancybox-slide--next {
//     opacity: 0;
//     transform: translate3d(100%, 0, 0);
// }

// .fancybox-fx-slide.fancybox-slide--current {
//     opacity: 1;
//     transform: translate3d(0, 0, 0);
// }

// /* transitionEffect: fade */

// .fancybox-fx-fade.fancybox-slide--previous,
// .fancybox-fx-fade.fancybox-slide--next {
//     opacity: 0;
//     transition-timing-function: cubic-bezier(.19, 1, .22, 1);
// }

// .fancybox-fx-fade.fancybox-slide--current {
//     opacity: 1;
// }

// /* transitionEffect: zoom-in-out */

// .fancybox-fx-zoom-in-out.fancybox-slide--previous {
//     opacity: 0;
//     transform: scale3d(1.5, 1.5, 1.5);
// }

// .fancybox-fx-zoom-in-out.fancybox-slide--next {
//     opacity: 0;
//     transform: scale3d(.5, .5, .5);
// }

// .fancybox-fx-zoom-in-out.fancybox-slide--current {
//     opacity: 1;
//     transform: scale3d(1, 1, 1);
// }

// /* transitionEffect: rotate */

// .fancybox-fx-rotate.fancybox-slide--previous {
//     opacity: 0;
//     -ms-transform: rotate(-360deg);
//     transform: rotate(-360deg);
// }

// .fancybox-fx-rotate.fancybox-slide--next {
//     opacity: 0;
//     -ms-transform: rotate(360deg);
//     transform: rotate(360deg);
// }

// .fancybox-fx-rotate.fancybox-slide--current {
//     opacity: 1;
//     -ms-transform: rotate(0deg);
//     transform: rotate(0deg);
// }

// /* transitionEffect: circular */

// .fancybox-fx-circular.fancybox-slide--previous {
//     opacity: 0;
//     transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
// }

// .fancybox-fx-circular.fancybox-slide--next {
//     opacity: 0;
//     transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
// }

// .fancybox-fx-circular.fancybox-slide--current {
//     opacity: 1;
//     transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
// }

// /* transitionEffect: tube */

// .fancybox-fx-tube.fancybox-slide--previous {
//     transform: translate3d(-100%, 0, 0) scale(.1) skew(-10deg);
// }

// .fancybox-fx-tube.fancybox-slide--next {
//     transform: translate3d(100%, 0, 0) scale(.1) skew(10deg);
// }

// .fancybox-fx-tube.fancybox-slide--current {
//     transform: translate3d(0, 0, 0) scale(1);
// }

// /* Styling for Small-Screen Devices */
// @media all and (max-height: 576px) {
//     .fancybox-slide {
//         padding-left: 6px;
//         padding-right: 6px;
//     }

//     .fancybox-slide--image {
//         padding: 6px 0;
//     }

//     .fancybox-close-small {
//         right: -6px;
//     }

//     .fancybox-slide--image .fancybox-close-small {
//         background: #4e4e4e;
//         color: #f2f4f6;
//         height: 36px;
//         opacity: 1;
//         padding: 6px;
//         right: 0;
//         top: 0;
//         width: 36px;
//     }

//     .fancybox-caption {
//         padding-left: 12px;
//         padding-right: 12px;
//     }

// }
// /* Share */

// .fancybox-share {
//     background: #f4f4f4;
//     border-radius: 3px;
//     max-width: 90%;
//     padding: 30px;
//     text-align: center;
// }

// .fancybox-share h1 {
//     color: #222;
//     font-size: 35px;
//     font-weight: 700;
//     margin: 0 0 20px 0;
// }

// .fancybox-share p {
//     margin: 0;
//     padding: 0;
// }

// .fancybox-share__button {
//     border: 0;
//     border-radius: 3px;
//     display: inline-block;
//     font-size: 14px;
//     font-weight: 700;
//     line-height: 40px;
//     margin: 0 5px 10px 5px;
//     min-width: 130px;
//     padding: 0 15px;
//     text-decoration: none;
//     transition: all .2s;
//     -webkit-user-select: none;
//     -moz-user-select: none;
//     -ms-user-select: none;
//     user-select: none;
//     white-space: nowrap;
// }

// .fancybox-share__button:visited,
// .fancybox-share__button:link {
//     color: #fff;
// }

// .fancybox-share__button:hover {
//     text-decoration: none;
// }

// .fancybox-share__button--fb {
//     background: #3b5998;
// }

// .fancybox-share__button--fb:hover {
//     background: #344e86;
// }

// .fancybox-share__button--pt {
//     background: #bd081d;
// }

// .fancybox-share__button--pt:hover {
//     background: #aa0719;
// }

// .fancybox-share__button--tw {
//     background: #1da1f2;
// }

// .fancybox-share__button--tw:hover {
//     background: #0d95e8;
// }

// .fancybox-share__button svg {
//     height: 25px;
//     margin-right: 7px;
//     position: relative;
//     top: -1px;
//     vertical-align: middle;
//     width: 25px;
// }

// .fancybox-share__button svg path {
//     fill: #fff;
// }

// .fancybox-share__input {
//     background: transparent;
//     border: 0;
//     border-bottom: 1px solid #d7d7d7;
//     border-radius: 0;
//     color: #5d5b5b;
//     font-size: 14px;
//     margin: 10px 0 0 0;
//     outline: none;
//     padding: 10px 15px;
//     width: 100%;
// }
// /* Thumbs */

// .fancybox-thumbs {
//     background: #ddd;
//     bottom: 0;
//     display: none;
//     margin: 0;
//     -webkit-overflow-scrolling: touch;
//     -ms-overflow-style: -ms-autohiding-scrollbar;
//     padding: 2px 2px 4px 2px;
//     position: absolute;
//     right: 0;
//     -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
//     top: 0;
//     width: 212px;
//     z-index: 99995;
// }

// .fancybox-thumbs-x {
//     overflow-x: auto;
//     overflow-y: hidden;
// }

// .fancybox-show-thumbs .fancybox-thumbs {
//     display: block;
// }

// .fancybox-show-thumbs .fancybox-inner {
//     right: 212px;
// }

// .fancybox-thumbs__list {
//     font-size: 0;
//     height: 100%;
//     list-style: none;
//     margin: 0;
//     overflow-x: hidden;
//     overflow-y: auto;
//     padding: 0;
//     position: absolute;
//     position: relative;
//     white-space: nowrap;
//     width: 100%;
// }

// .fancybox-thumbs-x .fancybox-thumbs__list {
//     overflow: hidden;
// }

// .fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar {
//     width: 7px;
// }

// .fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track {
//     background: #fff;
//     border-radius: 10px;
//     box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
// }

// .fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb {
//     background: #2a2a2a;
//     border-radius: 10px;
// }

// .fancybox-thumbs__list a {
//     -webkit-backface-visibility: hidden;
//     backface-visibility: hidden;
//     background-color: rgba(0, 0, 0, .1);
//     background-position: center center;
//     background-repeat: no-repeat;
//     background-size: cover;
//     cursor: pointer;
//     float: left;
//     height: 75px;
//     margin: 2px;
//     max-height: calc(100% - 8px);
//     max-width: calc(50% - 4px);
//     outline: none;
//     overflow: hidden;
//     padding: 0;
//     position: relative;
//     -webkit-tap-highlight-color: transparent;
//     width: 100px;
// }

// .fancybox-thumbs__list a::before {
//     border: 6px solid #ff5268;
//     bottom: 0;
//     content: '';
//     left: 0;
//     opacity: 0;
//     position: absolute;
//     right: 0;
//     top: 0;
//     transition: all .2s cubic-bezier(.25, .46, .45, .94);
//     z-index: 99991;
// }

// .fancybox-thumbs__list a:focus::before {
//     opacity: .5;
// }

// .fancybox-thumbs__list a.fancybox-thumbs-active::before {
//     opacity: 1;
// }

// /* Styling for Small-Screen Devices */
// @media all and (max-width: 576px) {
//     .fancybox-thumbs {
//         width: 110px;
//     }

//     .fancybox-show-thumbs .fancybox-inner {
//         right: 110px;
//     }

//     .fancybox-thumbs__list a {
//         max-width: calc(100% - 10px);
//     }
// }

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
  width: 100% !important;
}
.select2-container * {
  outline: none;
}
.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 40px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select2-container .select2-selection--single .select2-selection__clear {
  position: relative;
}
.select2-container[dir="rtl"]
  .select2-selection--single
  .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}
.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 40px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select2-container .select2-search--inline {
  float: left;
}
.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0;
}
.select2-container
  .select2-search--inline
  .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  background-color: white;
  border: 1px solid $gg;
  border-radius: 2px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
  font-size: 14px;
}
.select2-container--open .select2-dropdown {
}
.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__options::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background: #eee;
}

.select2-results__options::-webkit-scrollbar-thumb {
  background: #ccc;
}

.select2-results__option {
  padding: 7px 8px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-results__option[aria-selected] {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}
.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}
.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #b5cfe8;
  border-radius: 4px;
  font-size: 14px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: $b;
  line-height: 40px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  display: none !important;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  color: #999;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  position: absolute;
  width: 30px;
  background: url(../../images/select.svg) center no-repeat;
  height: 100%;
  right: 0;
  position: absolute;
  top: 0;
  transition: 0.1s;
  transform-origin: center;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  display: none;
}

.select2-container--default[dir="rtl"]
  .select2-selection--single
  .select2-selection__clear {
  float: left;
}

.select2-container--default[dir="rtl"]
  .select2-selection--single
  .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled
  .select2-selection--single {
  background-color: #eee;
  cursor: default;
}
.select2-container--default.select2-container--disabled
  .select2-selection--single
  .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow {
  transform: rotate(180deg);
}

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #b5cfe8;
  border-radius: 4px;
  cursor: text;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
  display: flex;
  overflow: hidden;
  height: 34px;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered
  li {
  list-style: none;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__placeholder {
  color: #999;
  margin-top: 5px;
  float: left;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  background-color: $bg2;
  border: 1px solid $gr;
  border-radius: 4px;
  cursor: default;
  font-size: 14px;
  line-height: 28px;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove:hover {
  color: #333;
}

.select2-container--default[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice,
.select2-container--default[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__placeholder,
.select2-container--default[dir="rtl"]
  .select2-selection--multiple
  .select2-search--inline {
  float: right;
}

.select2-container--default[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus
  .select2-selection--multiple {
  border: 1px solid #b5cfe8;
  outline: 0;
}

.select2-container--default.select2-container--disabled
  .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled
  .select2-selection__choice__remove {
  display: none;
}

.select2-container--default.select2-container--open.select2-container--above
  .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--above
  .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below
  .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below
  .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  border-radius: 2px;
}

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 260px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option[role="group"] {
  padding: 0;
}

.select2-container--default .select2-results__option[aria-disabled="true"] {
  color: #999;
}

.select2-container--default .select2-results__option[aria-selected="true"] {
  background-color: #ddd;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}
.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__group {
  padding-left: 0;
}
.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}
.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}
.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}
.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}
.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: $bl;
  color: $w;
}

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}
.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}
.select2-container--classic
  .select2-selection--single
  .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}
.select2-container--classic
  .select2-selection--single
  .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px;
}
.select2-container--classic
  .select2-selection--single
  .select2-selection__placeholder {
  color: #999;
}
.select2-container--classic
  .select2-selection--single
  .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0);
}
.select2-container--classic
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--classic[dir="rtl"]
  .select2-selection--single
  .select2-selection__clear {
  float: left;
}

.select2-container--classic[dir="rtl"]
  .select2-selection--single
  .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}
.select2-container--classic.select2-container--open
  .select2-selection--single
  .select2-selection__arrow {
  background: transparent;
  border: none;
}
.select2-container--classic.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--classic.select2-container--open.select2-container--above
  .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}

.select2-container--classic.select2-container--open.select2-container--below
  .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0);
}

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0;
}
.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}
.select2-container--classic
  .select2-selection--multiple
  .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px;
}
.select2-container--classic
  .select2-selection--multiple
  .select2-selection__clear {
  display: none;
}
.select2-container--classic
  .select2-selection--multiple
  .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}
.select2-container--classic
  .select2-selection--multiple
  .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}
.select2-container--classic
  .select2-selection--multiple
  .select2-selection__choice__remove:hover {
  color: #555;
}

.select2-container--classic[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice {
  float: right;
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--classic[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--classic.select2-container--open
  .select2-selection--multiple {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above
  .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below
  .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none;
}

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent;
}

.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
  border-top: none;
}

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--classic .select2-results__option[role="group"] {
  padding: 0;
}

.select2-container--classic .select2-results__option[aria-disabled="true"] {
  color: grey;
}

.select2-container--classic
  .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white;
}

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

.accordion {
  margin-bottom: 40px;
}

.card,
.card-header {
  border-color: #b5cfe8;
  position: relative;
}

.card-header {
  background-color: #f5f8ff;
  color: #3873ee;
}

.accordion-icon {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 16px;
  right: 15px;
  transition: 0.25s;
  // z-index: -1;
}

// .collapse +
.accordion-icon {
  transform: rotate(0deg);
}

// .collapse.show +
.accordion-icon.icon-rotate {
  transform: rotate(180deg);
}
