@import "./global/variables.scss";

// scss-docs-end grid-breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
//   xxl: 1400px
) !default;

// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px,
//   xxl: 1320px
) !default;

 $theme-colors: (
   primary: $bl
 ) !default;

//@import 'bootstrap/scss/bootstrap';


@import "bootstrap/scss/_functions";
@import "bootstrap/scss/_variables";
@import "bootstrap/scss/_mixins";
@import "bootstrap/scss/_root";
@import "bootstrap/scss/_type";
@import "bootstrap/scss/_images";
@import "bootstrap/scss/_code";
@import "bootstrap/scss/_grid";
@import "bootstrap/scss/_tables";
@import "bootstrap/scss/_forms";
@import "bootstrap/scss/_buttons";
//@import "bootstrap/scss/_transitions";
@import "bootstrap/scss/_dropdown";
@import "bootstrap/scss/_button-group";
@import "bootstrap/scss/_input-group";
@import "bootstrap/scss/_custom-forms";
//@import "bootstrap/scss/_nav";
//@import "bootstrap/scss/_navbar";
@import "bootstrap/scss/_card";
@import "bootstrap/scss/_breadcrumb";
@import "bootstrap/scss/_pagination";
@import "bootstrap/scss/_badge";
//@import "bootstrap/scss/_jumbotron";
@import "bootstrap/scss/_alert";
@import "bootstrap/scss/_progress";
@import "bootstrap/scss/_media";
@import "bootstrap/scss/_list-group";
@import "bootstrap/scss/_close";
@import "bootstrap/scss/_toasts";
@import "bootstrap/scss/_modal";
@import "bootstrap/scss/_tooltip";
@import "bootstrap/scss/_popover";
//@import "bootstrap/scss/_carousel";
@import "bootstrap/scss/_spinners";
@import "bootstrap/scss/_utilities";
//@import "bootstrap/scss/_print";