@import "../../styles/global/variables.scss";

.product-block {
    &__title {
        color: $bd;
        margin-bottom: 10px;
        word-break: break-word;
    }
    &__price {
        font-size: 14px;
        &_current {
            font-weight: 700;
        }
        &_old {
            color: $textdel;
            text-decoration: line-through;
            margin-right: 7px;
        }
    }
}

.img-block {
    position: relative;
    &__img {
        width: 100%;
        height: 197px;
        object-fit: cover;
        border-radius: 12px;
        margin-bottom: 10px;
    }
    &__discount {
        background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%), #ffffff;
        border-radius: 6px;
        padding: 3px 9px;
        color: $rd;
        position: absolute;
        top: 12px;
        right: 12px;
    }
}
