@import "../../styles/global/variables.scss";

.category-menu {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
    &__item {
        margin-bottom: 15px;
    }
    &__btn-link {
        color: $bd;
        margin-bottom: 0;
        font-weight: 500;
        background: none;
        text-align: left;
        border: 0;
        font-size: 16px;
        cursor: pointer;
        &_active {
            color: $bl;
        }
    }
}