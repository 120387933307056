.swiper-pagination {
    position: absolute;
    text-align: center;
    transition: 300ms;
    transform: translate3d(0, 0, 0);
    z-index: 10;
}

.swiper-button-prev,
.swiper-button-next {
    z-index: 10;
}

.mainslider-slider {
    position: relative;
}

.services-item__name {
    text-transform: uppercase;
}

.select svg path {
    fill: rgb(56, 115, 238);
}

input[type='number'] {
    -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.form-checkbox, .form-radio {
    align-items: center;
}

.custom-control-label::before,
.custom-control-label::after {
    border-radius: 2px;
    border: 2px solid #C4C8CB;
    width: 20px;
    height: 20px;
    left: 0px;
    top: 0px;
}

.custom-radio .custom-control-label::after {
    border: none;
}

.custom-control-label::after {
    background-size: 100%;
}

.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    border-color: #007bff;
    background-image: url("../../images/check.svg");
}

.cart-head__check .custom-control-label {
    font-weight: 500;
}

.custom-control-label {
    padding-left: 30px;
}

.custom-control {
    margin-right: 10px;
    padding: 0;
    min-height: 20px;
}

.custom-control-label::after {
    background-size: 70%;
}

.inputlabel {
    display: block;
}

.addprod-image {
    max-height: 550px;
}

.cke {
    border: 1px solid #B5CFE8 !important;
    border-radius: 4px;
    overflow: hidden;
}

.cke_bottom {
    border-top: 1px solid #B5CFE8 !important;
}

.cke_top {
    border-bottom: 1px solid #B5CFE8 !important;
}

.empty-list {
    padding: 15px;
}