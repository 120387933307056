main.main {
  padding-top: 115px;
  padding-bottom: 1px;
  min-height: calc(100vh - 268px);

  @include md {
    padding-top: 60px;
  }
  @include sm {
    padding-top: 50px;
  }
  &--bg {
    background: $bg;
  }
  &--grad {
    &:before {
      background: linear-gradient(
        198.05deg,
        #b5cfe8 0%,
        rgba(181, 207, 232, 0) 40%,
        #b5cfe8 95%
      );
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      z-index: -1;
    }
  }
}

.form-group--top {
  z-index: 10;
}

.mainslider {
  background: $bg;
  margin-bottom: 70px;

  .container {
    position: relative;
  }
  &-item {
    @include row(15);

    &__left {
      @include col(5, 15);
      @include flex(ct);
      @include flexcol(n);

      p {
        margin: 0;
      }
      @include xs {
        @include col(12, 15);
        padding-bottom: 20px;
      }
    }
    &__right {
      @include col(7, 15);

      @include xs {
        @include col(12, 15);
        order: -1;
        margin-bottom: 15px;
      }
    }
    &__heading {
      @include h(1);
      margin-top: 10px;
      margin-bottom: 25px;

      @include sm {
        margin-bottom: 10px;
      }
    }
    &__text {
      font-size: 18px;
      font-weight: 300;
      margin-bottom: 40px;

      @include sm {
        font-size: 16px;
        margin-bottom: 15px;
      }
    }
    &__img {
      display: block;
    }
  }
  .swiper-container {
  }
  .swiper-button-next {
    width: 50px;
    height: 50px;
    background: $w;
    position: absolute;
    bottom: 20px;
    right: 0;
    top: auto;

    @include sm {
      display: none;
    }
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url(../../images/arwb.svg) center no-repeat;
      content: "";
    }
  }
  .swiper-button-prev {
    width: 50px;
    height: 50px;
    background: $w;
    position: absolute;
    bottom: 20px;
    right: 65px;
    top: auto;
    left: auto;

    @include sm {
      display: none;
    }
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url(../../images/arwb.svg) center no-repeat;
      transform: scaleX(-1);
      content: "";
    }
  }
  .swiper-pagination {
    width: 100%;
    text-align: center;
    left: 0;
    bottom: -40px;
  }
  .swiper-pagination-bullet {
    outline: none;
    background: transparent;
    opacity: 1;
    width: 24px;
    height: 24px;
    position: relative;
    margin: 0 5px;

    &:after {
      position: absolute;
      top: 9px;
      left: 9px;
      background: $gg;
      border-radius: 100%;
      content: "";
      width: 6px;
      height: 6px;
      transition: 0.2s;
    }
  }
  .swiper-pagination-bullet:hover,
  .swiper-pagination-bullet-active {
    &:after {
      top: 8px;
      left: 8px;
      background: $bl;
      width: 8px;
      height: 8px;
    }
  }
}

.news {
  margin-bottom: 70px;

  &-heading {
    border-bottom: 1px solid $bg;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  &-head {
    margin-bottom: 20px;
  }
  &-items {
    @include row(15);

    @include md {
      @include row(10);
    }
  }
  &-item {
    @include col(4, 15);
    margin-bottom: 30px;
    @include flex(lt);
    @include flexcol(n);
    position: relative;

    @include md {
      @include col(6, 10);
      margin-bottom: 20px;
    }
    @include xs {
      @include col(12, 10);
    }
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      border: 1px solid $bg;
    }
    &__imagewrap {
      position: relative;
      z-index: 2;
      background-color: $bg;
      width: 100%;
      height: 230px;
      overflow: hidden;

      @include lg {
        height: 200px;
      }
      @include sm {
        height: 180px;
      }
    }
    &__image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      display: block;
      transition: 10s;
    }
    &:hover &__image {
      transform: scale(1.1);
    }
    &__content {
      padding: 20px 15px;

      @include sm {
        padding: 15px;
      }
    }
    &__title {
      @include h(4);
      color: $b;
      transition: 0.2s;
      min-height: 60px;
      margin-bottom: 10px;

      @include sm {
        min-height: inherit;
      }
    }
    &:hover &__title {
      color: $bb;
    }
    &__date {
      @extend %p14;
      color: #a3a8ab;
    }
  }
  &-btm {
    text-align: center;

    .btn {
      min-width: 230px;
    }
  }
  &-content {
    background: $w;
    box-shadow: 0px 0px 24px rgba(35, 111, 153, 0.08);
    padding: 60px;

    @include lg {
      padding: 30px;
      margin-left: -30px;
      margin-right: -30px;
    }
    @include sm {
      padding: 15px;
      margin-left: -15px;
      margin-right: -15px;
    }
    &__heading {
      @include h(2);
    }
  }
  &-content + &-head {
    border-bottom: 0;
    padding-bottom: 0;
  }
  &-text {
    line-height: 1.5;
  }
  &rel {
    background: $w;
    padding: 70px 0;

    .container {
      position: relative;
    }
    .news-item {
      width: 100% !important;
      margin: 0 !important;
    }
    .swiper-button-next {
      width: 50px;
      height: 50px;
      background: $bg2;
      position: absolute;
      right: 30px;
      top: 10px;

      @include sm {
        right: 15px;
      }
      &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(../../images/arwb.svg) center no-repeat;
        content: "";
      }
    }
    .swiper-button-prev {
      width: 50px;
      height: 50px;
      background: $bg2;
      position: absolute;
      right: 95px;
      top: 10px;
      left: auto;

      @include sm {
        right: 75px;
      }
      &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(../../images/arwb.svg) center no-repeat;
        transform: scaleX(-1);
        content: "";
      }
    }
  }
}

.links {
  padding: 60px 0;
  background: $bg;

  &-heading {
    @include h(3);
    margin-bottom: 20px;
  }
  &-items {
    @include row(10);
  }
  &-item {
    @include col(4, 10);
    margin-bottom: 20px;
    background: $w;
    transition: 0.2s;
    @include flex(lc);
    position: relative;
    overflow: hidden;

    @include md {
      @include col(6, 10);
    }
    @include sm {
      margin-bottom: 10px;
    }
    @include xs {
      @include col(12, 10);
    }
    &__imagewrap {
      width: 106px;
      height: 106px;
      @include flex(cc);
      flex-shrink: 0;

      @include sm {
        width: 86px;
        height: 86px;
      }
    }
    &__image {
      max-width: 86px;
      max-height: 86px;
      display: block;

      @include sm {
        max-width: 66px;
        max-height: 66px;
      }
    }
    &__content {
      padding: 10px;
      @extend %p14;
      color: $b;
      transition: 0.2s;

      @include sm {
        padding: 5px;
      }
    }
    &:hover &__content {
      color: $bb;
    }
    &:hover {
      box-shadow: 12px 12px 24px rgba(35, 111, 153, 0.08),
        -12px -12px 24px rgba(35, 111, 153, 0.08);
    }
    &:before {
      width: 2px;
      height: 100%;
      background: $bl;
      top: 0;
      left: 0;
      position: absolute;
      content: "";
      left: -2px;
      transition: 0.2s;
      opacity: 0;
    }
    &:hover:before {
      left: 0;
      opacity: 1;
    }
  }
}

.breadcrumbs {
  margin: 20px 0;
  overflow: hidden;

  @include sm {
    margin: 10px 0 20px;
  }
  &-link {
    padding: 5px 0;
    font-size: 12px;
    margin-right: 18px;
    position: relative;
    display: inline-block;
    color: $bl;

    @include sm {
      padding: 0;
    }
    &:before {
      width: 6px;
      height: 100%;
      position: absolute;
      top: 0;
      right: -12px;
      content: "";
      background: url(../../images/bread.svg) center no-repeat;
    }
    &:last-child:before {
      display: none;
    }
  }
}

.loadmore {
  width: 100%;
  background: #cddcfa;
  color: $bl;
  margin-top: 20px;
}

.pagi {
  @include flex(lt);
  flex-wrap: wrap;
  margin-top: 30px;

  &-link {
    @include flex(cc);
    min-width: 30px;
    height: 30px;
    padding: 3px 5px;
    border-radius: 4px;
    color: $gg;
    background-color: transparent;
    border: 1px solid $gg;
    margin-right: 10px;
    margin-bottom: 10px;
    text-align: center;
    cursor: pointer;

    &:hover {
      color: $bl;
      border-color: $bl;
    }
    &--active {
      color: $w !important;
      background: $bl;
      border-color: $bl;
    }
  }
  &-prev {
    border-color: transparent;
    background: url(../../images/arwb.svg) center no-repeat;
    transform: scaleX(-1);
  }
  &-next {
    border-color: transparent;
    background: url(../../images/arwb.svg) center no-repeat;
  }
}

.pageslider {
  position: relative;
  margin: 30px 0;
  width: 100%;

  &-slide {
    padding: 0 70px;

    @include sm {
      padding: 0;
    }
  }
  &-img {
    width: 100%;
    margin-bottom: 10px;

    @include sm {
      margin-bottom: 0;
    }
  }
  &-text {
    color: $gt;
    text-align: center;

    @include sm {
      font-size: 12px;
      padding: 0 60px;
    }
  }
  .swiper-button-next {
    width: 50px;
    height: 50px;
    background: $bg;
    position: absolute;
    top: 50%;
    margin-top: -25px;
    right: 0;

    @include sm {
      top: auto;
      bottom: 0;
      margin-top: auto;
    }
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url(../../images/arwb.svg) center no-repeat;
      content: "";
    }
  }
  .swiper-button-prev {
    width: 50px;
    height: 50px;
    background: $bg;
    position: absolute;
    top: 50%;
    margin-top: -25px;
    left: 0px;

    @include sm {
      top: auto;
      bottom: 0;
      margin-top: auto;
    }
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url(../../images/arwb.svg) center no-repeat;
      transform: scaleX(-1);
      content: "";
    }
  }
}

.catalog {
  padding-bottom: 70px;

  &-heading {
    margin-bottom: 20px;

    span {
      color: $bl;
    }
  }
  &-map {
    height: 200px;
    width: 100%;
    @include flex(cc);
    position: relative;
    transition: 0.3s;
    margin-bottom: 40px;

    &--opened {
      height: 430px;
    }
    &__map {
      width: 100%;
      height: 100%;
      position: relative;
    }
    &__map > div {
      width: 100%;
      height: 100%;
      position: relative;
    }
    &__wrapper {
      position: "relative";
      overflow: "hidden";
    }
    &__overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      @include flex(cc);
      text-align: center;
      z-index: 3;
      cursor: pointer;

      &:before {
        background: $bd;
        opacity: 0.5;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
      }
    }
    &--opened &__overlay {
      display: none;
    }
    &__intro {
      font-weight: 500;
      max-width: 320px;
      color: $w;
      position: relative;
      z-index: 4;
    }
    &__show {
      border: none;
      width: 30px;
      height: 30px;
      border-radius: 0px;
      padding: 0px;
      min-width: auto;
      background: $bl url(../../images/arww.svg) center no-repeat;
      position: absolute;
      bottom: 0;
      right: 0;
      display: block;
      z-index: 6;
      transform: rotate(90deg);
      cursor: pointer;
    }
    &--opened &__show {
      transform: rotate(-90deg);
    }
  }
  &-filters {
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid $bg;

    &__row {
      @include row(10);
    }
    &__col {
      @include col(2.4, 10);
      margin-bottom: 10px;

      @include lg {
        @include col(3, 10);
      }
      @include md {
        @include col(6, 10);
      }
      @include xs {
        @include col(12, 10);
      }
    }
    &__col2 {
      @include col(4, 10);
      margin-bottom: 10px;

      @include md {
        @include col(6, 10);
      }
      @include xs {
        @include col(12, 10);
      }
    }
    &__collast {
      @include col(2.4, 10);
      @include flex(rb);
      margin-bottom: 10px;
      margin-left: auto;

      @include lg {
        @include col(12, 10);
      }
    }
    &__reset {
      font-size: 14px;
      color: $gt;
      @include flex(lc);
      margin-left: auto;
      margin-bottom: 10px;
      position: relative;
      padding-left: 15px;
      cursor: pointer;
      background: transparent;
      border: none;

      &:hover {
        color: $bl;
      }
      &:before {
        width: 10px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: url(../../images/reset.svg) center no-repeat;
        content: "";
        filter: grayscale(1);
        transition: 0.2s;
      }
      &:hover:before {
        filter: grayscale(0);
      }
    }
  }
  &-items {
    @include row(10);

    @include sm {
      @include row(5);
    }
  }
  &-item {
    @include col(2.4, 10);
    margin-bottom: 20px;
    background: $w;

    @include lg {
      @include col(3, 10);
    }
    @include md {
      @include col(6, 10);
    }
    @include sm {
      @include col(6, 5);
      margin-bottom: 10px;
    }
    &--add {
      cursor: pointer;
      display: block;
      background: #f5f8ff;
      border: 1px solid $gg;
      box-shadow: -6px 6px 10px rgba(68, 88, 107, 0.05);
      border-radius: 4px;
      min-height: 290px;
      @include flex(cc);

      &:hover {
        border-color: $bl;
      }
    }
    &__add {
      padding-top: 65px;
      display: block;
      text-align: center;
      color: $bl;
      position: relative;

      &:before {
        width: 100%;
        height: 50px;
        content: "";
        top: 0;
        left: 0;
        background: url(../../images/plus.svg) center no-repeat;
        position: absolute;
      }
    }
    &__imgwrap {
      height: 138px;
      width: 100%;
      position: relative;
      display: block;

      @include sm {
        height: 90px;
      }
    }
    &__img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-image: url(../../images/placeholder.svg);
      background-color: $bg;
    }
    &__desc {
      border-left: 1px solid $bg;
      border-right: 1px solid $bg;
      padding: 10px;
      display: block;
      min-height: 100px;

      @include sm {
        padding: 5px;
        min-height: inherit;
      }
    }
    &__prod {
      font-size: 12px;
      color: $gr;
      margin-bottom: 10px;

      @include sm {
        margin-bottom: 5px;
      }
    }
    &__name {
      max-width: calc(100% - 5px);
      font-size: 14px;
      color: $b;
      margin-bottom: 10px;
      display: inline-block;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      &:hover {
        color: $bl;
      }
    }
    &__btm {
      border: 1px solid $bg;
      padding: 10px;

      @include flex(sc);

      @include sm {
        flex-wrap: wrap;
        padding: 5px;
      }
    }
    &__prices {
      margin-right: 5px;
      width: 80%;
      @include sm {
      }
    }
    &__price {
      font-size: 18px;
      font-weight: 500;
      line-height: 18px;
      color: $bd;

      span {
        font-size: 12px;
        font-weight: normal;
      }
    }
    &__value {
      font-size: 12px;
      color: $gr;
      line-height: 12px;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    &__buys {
      width: 110px;
      position: relative;
      @include flex(rc);

      @include sm {
        width: 100%;
      }
    }
    &__quants {
      width: 100%;
    }
    &__buy {
      width: 30px;
      height: 30px;
      background: $w;
      display: block;
      transition: 0.2s;
      position: relative;

      &:after {
        background: url(../../images/catcart.svg) center no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
      }
      &:hover {
        background: $bl;
      }
      &:hover:after {
        filter: brightness(20) grayscale(1);
      }
    }
    &__edit {
      width: 30px;
      height: 30px;
      background: $w;
      display: block;
      transition: 0.2s;
      position: relative;
      border: none;

      &:after {
        background: url(../../images/edit.svg) center no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
      }
      &:hover {
        background: $bl;
      }
      &:hover:after {
        filter: brightness(20) grayscale(1);
      }
    }
    &__del {
      width: 30px;
      height: 30px;
      background: $w;
      display: block;
      transition: 0.2s;
      position: relative;
      margin-right: 10px;

      &:after {
        background: url(../../images/delete.svg) center no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
      }
      &:hover {
        background: $rd;
      }
      &:hover:after {
        filter: brightness(20) grayscale(1);
      }
    }
  }
}

.product {
  margin-bottom: 70px;

  &-wrap {
    background: $w;
    box-shadow: 0px 0px 24px rgba(35, 111, 153, 0.08);
    padding: 60px;

    @include lg {
      padding: 30px;
      margin-left: -30px;
      margin-right: -30px;
    }
    @include sm {
      padding: 15px;
      margin-left: -15px;
      margin-right: -15px;
    }
  }
  &-row {
    @include row(15);
  }
  &-left {
    @include col(5, 15);

    @include sm {
      @include col(12, 15);
      margin-bottom: 14px;
    }
  }
  &-right {
    @include col(7, 15);

    @include sm {
      @include col(12, 15);
      margin-bottom: 14px;
    }
  }
  &-heading {
    @include h(3);
    margin-bottom: 20px;
  }
  &-manuf {
    color: $gr;
  }
  &-img {
    width: 100%;
  }
  &-prices {
    width: 100%;
    margin-top: 60px;
    background: $w;
    box-shadow: 12px 12px 24px rgba(35, 111, 153, 0.08),
      -12px -12px 24px rgba(35, 111, 153, 0.08);
    padding: 25px;
    @include flex(sc);

    @include md {
      flex-wrap: wrap;
      margin-top: 30px;
    }
    @include sm {
      padding: 15px;
    }
  }
  &-price {
    font-size: 36px;
    font-weight: 500;
    color: $bd;
    width: calc(100% - 190px);
    &__unit {
      display: inline-block;
      width: 50%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    @include md {
      width: 100%;
      margin-bottom: 15px;
      font-size: 26px;
    }
    span {
      font-size: 14px;
    }
    strong {
      font-size: 14px;
      color: $gr;
      font-weight: normal;
    }
  }
  &-buys {
    width: 190px;
    margin-left: auto;
  }
  &-buy {
    margin-right: 0;
    &:after {
      width: 17px;
      height: 17px;
      background: url(../../images/cartw.svg) center no-repeat;
    }
  }
  &-quants {
    .counter-input__field input {
      line-height: 50px;
      height: 50px;
      font-size: 18px;
      font-weight: 500;
    }
    .counter-input__spin {
      width: 50px;
      height: 50px;

      &:after {
        background-size: 14px;
      }
    }
  }
}
.catalog-item {
  &__quants {
    .counter-input__field input {      
      min-width: 95px;
    }
  }
}
.catslider {
  position: relative;
  margin-bottom: 70px;

  .catalog-item {
    width: 100%;
    background: $w;
    margin: 0;
  }
  .container {
    position: relative;
  }
  .swiper-button-next {
    width: 50px;
    height: 50px;
    background: $bg;
    position: absolute;
    right: 30px;
    top: 10px;

    @include sm {
      right: 15px;
    }
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url(../../images/arwb.svg) center no-repeat;
      content: "";
    }
  }
  .swiper-button-prev {
    width: 50px;
    height: 50px;
    background: $bg;
    position: absolute;
    right: 95px;
    top: 10px;
    left: auto;

    @include sm {
      right: 75px;
    }
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url(../../images/arwb.svg) center no-repeat;
      transform: scaleX(-1);
      content: "";
    }
  }
}

.cart {
  margin-bottom: 70px;

  &-heading {
    margin-bottom: 20px;
  }
  &-empty {
    background: $w;
    box-shadow: 0px 0px 24px rgba(35, 111, 153, 0.08);
    padding: 60px;

    @include lg {
      padding: 30px;
      margin-left: -30px;
      margin-right: -30px;
    }
    @include sm {
      padding: 15px;
      margin-left: -15px;
      margin-right: -15px;
    }
    &__row {
      @include row(15);
    }
    &__left {
      @include col(5, 15);
      text-align: center;

      @include md {
        @include col(12, 15);
      }
    }
    &__right {
      @include col(5, 15);
      text-align: center;

      @include md {
        @include col(12, 15);
      }
      p {
        color: $gt;
      }
    }
    &__btm {
      margin-top: 50px;

      @include sm {
        margin-top: 15px;
      }
    }
  }
  &-row {
    @include row(15);
    position: relative;

    @include lg {
      @include row(10);
    }
  }
  &-left {
    @include col(9, 15);

    @include lg {
      @include col(9, 10);
    }
    @include md {
      @include col(12, 15);
      margin-bottom: 30px;
    }
  }
  &-right {
    @include col(3, 15);
    position: sticky;
    top: 100px;
    height: 100%;

    @include lg {
      @include col(3, 10);
    }
    @include md {
      @include col(12, 15);
    }
  }
  &-block {
    background: $w;
    box-shadow: 0px 0px 24px rgba(35, 111, 153, 0.08);
    padding: 30px;

    @include lg {
      padding: 20px;
    }
    @include sm {
      padding: 15px;
      margin-left: -20px;
      margin-right: -20px;
    }
  }
  &-footer {
    border-top: 1px solid $bg;
    padding-top: 20px;
    @include flex(lc);
    font-size: 14px;
    font-weight: 500;
    color: $gt;

    &__check {
      margin: 0;
      margin-right: 10px;
      color: $rd;

      .form-checkbox__label {
        font-weight: 500;
      }
    }
  }
  &-head {
    border-bottom: 1px solid $bg;
    padding-bottom: 20px;
    @include flex(lc);
    font-size: 14px;
    font-weight: 500;
    color: $gt;

    &__check {
      margin: 0;
      margin-right: 10px;
      color: $rd;

      &:hover {
        text-decoration: underline;
      }
      .form-checkbox__label {
        font-weight: 500;
      }
    }
    &__name {
    }
    &__price {
      width: 120px;
      margin-left: auto;
      text-align: center;

      @include lg {
        width: 110px;
      }
      @include sm {
        display: none;
      }
    }
    &__quant,
    &__sum {
      width: 120px;
      margin-left: 30px;
      text-align: center;

      @include lg {
        width: 110px;
        margin-left: 20px;
      }
      @include sm {
        display: none;
      }
    }
  }
  &-items {
    border-bottom: 1px solid $bg;

    &__head {
    }
    &__quant {
      width: 120px;
      margin-left: auto;
      text-align: center;
      font-size: 16px;
      flex-shrink: 0;
      color: $bb;

      @include lg {
        width: 110px;
      }
      @include sm {
        display: none;
      }
    }
    &__sum {
      width: 120px;
      margin-left: 30px;
      text-align: center;
      font-size: 18px;
      flex-shrink: 0;
      color: $bb;

      @include lg {
        width: 110px;
        margin-left: 20px;
      }
      @include sm {
        display: none;
      }
    }
  }
  &-item {
    @include flex(lc);
    padding: 7px 0;
    border-bottom: 1px solid $bg;

    @include sm {
      @include flex(lt);
      flex-wrap: wrap;
    }
    &:last-child {
      border-bottom: 0;
    }
    &__wrap {
      @include flex(lc);

      @include sm {
      }
    }
    &__check {
      margin: 0;
    }
    &__img {
      width: 40px;
      display: block;
      margin-right: 15px;
    }
    &__namewrap {
      line-height: 1;
      max-width: 280px;

      @include sm {
        width: 100%;
      }
    }
    &__name {
      font-size: 14px;
      text-decoration: underline;
      display: inline-block;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    &__prices {
      width: 120px;
      margin-left: auto;
      text-align: center;
      font-size: 16px;
      flex-shrink: 0;

      @include lg {
        width: 110px;
      }
      @include sm {
        width: 50%;
        margin: 15px 0 0;
      }
    }
    &__quants,
    &__sum {
      width: 120px;
      margin-left: 30px;
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      flex-shrink: 0;

      @include lg {
        width: 110px;
        margin-left: 20px;
      }
      @include sm {
        width: 100%;
        margin: 15px 0 0;
      }
    }
    &__quants {
      @include sm {
        width: 50%;
      }
    }
    &__label {
      font-size: 12px;
      color: $gr;
      display: none;

      @include sm {
        display: block;
      }
    }
    &--not-active {
      opacity: 0.5;
    }
    &__not-sale {
      font-size: 14px;
    }
  }
  &-total {
    background: $w;
    box-shadow: 0px 0px 24px rgba(35, 111, 153, 0.08);

    @include sm {
      margin-left: -20px;
      margin-right: -20px;
    }
    &__top,
    &__mid,
    &__btm {
      padding: 20px;

      @include sm {
        padding: 15px;
      }
    }
    &__btm .btn {
      padding-left: 10px;
      padding-right: 10px;
    }
    &__mid {
      border-top: 1px solid $bg;
      border-bottom: 1px solid $bg;
    }
    &__items {
      @include flex(sc);
      margin-top: 10px;
    }
    &__name {
      color: $gt;
      font-size: 14px;
    }
    &__value {
      font-weight: 500;
      font-size: 18px;
    }
  }
  &-last {
    margin-top: 20px;
    @include flex(cc);
  }
  &-clear {
    @include flex(cc);
    color: $rd;
    filter: grayscale(1);
    opacity: 0.7;
    font-size: 14px;
    background-color: inherit;
    border: none;

    &:hover {
      filter: grayscale(0);
      opacity: 1;
      color: $rd;
    }
    &:before {
      width: 30px;
      height: 30px;
      background: url(../../images/cartclear.svg) center no-repeat;
      content: "";
      margin-right: 5px;
    }
  }
}

.manuf {
  margin-bottom: 50px;
  @include row(15);

  &-imgwrap {
    @include col(2, 15);

    @include xs {
      @include col(12, 15);
    }
  }
  &-img {
    width: 100%;
    display: block;

    @include xs {
      max-width: 220px;
      margin-bottom: 20px;
    }
  }
  &-content {
    @include col(10, 15);

    @include xs {
      @include col(12, 15);
    }
  }
  &-name {
    @include h(2);
    margin-bottom: 20px;
  }
  &-desc {
    color: $gt;
    margin-bottom: 20px;
  }
  &-label {
    color: $gr;
    margin-bottom: 5px;
  }
  &-value {
    margin-bottom: 10px;
  }
  &-row {
    @include row(15);
  }
  &-inn {
    @include col(3, 15);

    @include xs {
      @include col(12, 15);
    }
  }
  &-addr {
    @include col(9, 15);

    @include xs {
      @include col(12, 15);
    }
  }
  &-cont {
    @include col(4, 15);

    @include xs {
      @include col(12, 15);
    }
  }
  &-group {
    margin-top: 20px;

    @include xs {
      margin-top: 0;
    }
  }
}

.services {
  &-heading {
    margin-bottom: 20px;
  }
  &-items {
    @include row(10);
    margin-bottom: 70px;
  }
  &-item {
    @include col(4, 10);
    background: #f5f8ff;
    border: 1px solid #f5f8ff;
    @include flex(lt);
    padding: 20px;
    margin-bottom: 20px;
    color: $bd;

    @include md {
      @include col(6, 10);
    }
    @include sm {
      @include col(6, 5);
      padding: 10px;
      margin-bottom: 10px;
    }
    @include xs {
      @include col(12, 10);
    }
    &__img {
      width: 72px;
      margin-right: 20px;
      display: block;
      flex-shrink: 0;

      @include lg {
        width: 50px;
      }
      @include sm {
        margin-right: 15px;
      }
    }
    &__text {
      width: 100%;
    }
    &__name {
      font-size: 18px;
      font-weight: 500;
      min-height: 52px;
      @include flex(lc);
      margin-bottom: 5px;
      transition: 0.2s;
    }
    &__desc {
      font-size: 14px;
      font-weight: 300;
    }
    &:hover {
      border-color: #e5e8ef;
      box-shadow: 12px 12px 24px rgba(35, 111, 153, 0.04),
        -12px -12px 24px rgba(35, 111, 153, 0.04);
    }
    &:hover &__name {
      color: $bl;
    }
  }
  &-intro {
    background: $w;
    @include flex(st);
    margin-bottom: 20px;
    box-shadow: 12px 12px 24px rgba(35, 111, 153, 0.04),
      -12px -12px 24px rgba(35, 111, 153, 0.04);

    @include md {
      flex-wrap: wrap;
    }
    &__left {
      padding: 40px;

      @include lg {
        padding: 30px;
      }
      @include sm {
        padding: 15px;
      }
    }
    &__right {
      flex-shrink: 0;

      @include sm {
        flex-shrink: 1;
      }
    }
    &__img {
      display: block;
      max-width: 100%;
    }
    &__heading {
      margin-bottom: 30px;

      @include sm {
        margin-bottom: 15px;
      }
    }
    &__text {
      font-size: 18px;

      @include sm {
        font-size: 16px;
      }
    }
  }
  &-intro2 {
    margin-bottom: 20px;

    &__top {
      @include flex(sc);

      @include md {
        flex-wrap: wrap;
      }
    }
    &__left {
      max-width: 600px;

      @include lg {
      }
      @include sm {
      }
    }
    &__right {
      flex-shrink: 0;
      @include flex(cb);
      text-align: center;

      @include sm {
        flex-shrink: 1;
      }
    }
    &__img {
      display: block;
      max-width: 100%;
      margin-right: 30px;

      @include sm {
        margin-right: 0;
      }
    }
    &__heading {
      margin-bottom: 30px;

      @include sm {
        margin-top: 15px;
        margin-bottom: 15px;
      }
    }
    &__text {
      font-size: 18px;

      @include sm {
        font-size: 16px;
        margin-bottom: 15px;
      }
    }
    &__btm {
      background: $w;
      padding: 50px;
      box-shadow: 12px 12px 24px rgba(35, 111, 153, 0.04),
        -12px -12px 24px rgba(35, 111, 153, 0.04);

      @include lg {
        padding: 30px;
      }
      @include sm {
        padding: 15px;
        text-align: center;
      }
    }
    &__row {
      @include row(15);
    }
    &__col {
      @include col(3, 15);

      @include md {
        @include col(6, 15);
        margin-bottom: 20px;
      }
      @include xs {
        @include col(12, 15);
      }
    }
    &__value {
      @include h(3);
      margin-bottom: 5px;
      color: $bd;

      @include sm {
        margin-bottom: 0;
      }
    }
    &__label {
      font-size: 14px;
      color: $gt;
    }
  }
}

.health {
  margin-bottom: 70px;

  &-items {
    @include row(10);
  }
  &-item {
    @include col(4, 10);
    background: $w;
    padding: 40px;
    @include flex(lt);
    @include flexcol(n);
    margin-bottom: 20px;
    box-shadow: 12px 12px 24px rgba(35, 111, 153, 0.04),
      -12px -12px 24px rgba(35, 111, 153, 0.04);

    @include lg {
      padding: 30px;
    }
    @include md {
      @include col(6, 10);
    }
    @include sm {
      padding: 15px;
    }
    @include xs {
      @include col(12, 10);
    }
    &__icon {
      display: block;
      margin-bottom: 30px;

      @include sm {
        margin-bottom: 15px;
      }
    }
    &__name {
      @include h(3);
      margin-bottom: 20px;

      @include sm {
        margin-bottom: 10px;
      }
    }
    &__text {
      font-size: 18px;
      margin-bottom: 40px;

      @include sm {
        font-size: 16px;
        margin-bottom: 20px;
      }
    }
    &__btm {
      margin-top: auto;
      width: 100%;
    }
  }
}

.servlist {
  margin-bottom: 70px;

  &-heading {
    margin-bottom: 20px;
  }
  &-table {
    border: 0;

    th {
      font-size: 14px;
      color: $gt;
    }
    td {
      padding: 0;
      color: $gt;

      strong,
      strong a {
        width: 100%;
        height: 100%;
        display: inline-block;
        color: $b;
        &:hover {
          color: $bl;
        }
      }

      strong a {
        padding: 15px;
      }
    }
    &__name {
      font-weight: 500;
      color: $bd;
      word-break: break-word;
    }
    a.servlist-table__name {
      color: $bl;

      &:hover {
        color: $bb;
      }
    }
    &__tname {
      @include flex(lc);

      &:before {
        width: 20px;
        height: 20px;
        background: url(../../images/tname.svg) center no-repeat;
        margin-right: 10px;
        content: "";
      }
    }
    &__taddr {
      @include flex(lc);

      &:before {
        width: 20px;
        height: 20px;
        background: url(../../images/taddr.svg) center no-repeat;
        margin-right: 10px;
        content: "";
      }
    }
    &__tinfo {
      @include flex(lc);

      &:before {
        width: 20px;
        height: 20px;
        background: url(../../images/tinfo.svg) center no-repeat;
        margin-right: 10px;
        content: "";
      }
    }
    &__tsale {
      @include flex(lc);

      &:before {
        width: 20px;
        height: 20px;
        background: url(../../images/tsale.svg) center no-repeat;
        margin-right: 10px;
        content: "";
      }
    }
    &__tadd {
      padding: 0;
    }
    &__actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.servblocks {
  margin-top: 70px;
  margin-bottom: 70px;

  &-heading {
    margin-bottom: 20px;
  }
  &-items {
    @include row(15);

    @include md {
      @include row(10);
    }
  }
  &-item {
    @include col(4, 15);
    padding: 40px;
    @include flex(lt);
    @include flexcol(n);
    margin-bottom: 30px;
    color: $bd;
    background: $w;
    box-shadow: 12px 12px 24px rgba(35, 111, 153, 0.04),
      -12px -12px 24px rgba(35, 111, 153, 0.04);

    &:hover {
      box-shadow: 12px 12px 24px rgba(35, 111, 153, 0.08),
        -12px -12px 24px rgba(35, 111, 153, 0.08);
    }
    @include lg {
      padding: 30px;
    }
    @include md {
      @include col(6, 10);
      margin-bottom: 20px;
    }
    @include sm {
      padding: 15px;
    }
    @include xs {
      @include col(12, 10);
    }
    &__imgwrap {
    }
    &__img {
      display: block;
      margin-bottom: 30px;
      height: 50px;

      @include sm {
        margin-bottom: 15px;
      }
    }
    &__name {
      @include h(3);
      margin-bottom: 20px;
      transition: 0.2s;

      @include sm {
        margin-bottom: 10px;
      }
    }
    &:hover &__name {
      color: $bl;
    }
    &__text {
      font-size: 18px;
      margin-bottom: 40px;

      @include sm {
        font-size: 16px;
        margin-bottom: 20px;
      }
    }
    &__btm {
      margin-top: auto;
      width: 100%;
    }
  }
}

.credits {
  &-intro {
    @include flex(sc);

    @include md {
      flex-wrap: wrap;
    }
    &__left {
      width: 100%;

      @include lg {
      }
      @include sm {
      }
    }
    &__right {
      flex-shrink: 0;
      @include flex(cb);
      text-align: center;

      @include sm {
        flex-shrink: 1;
      }
    }
    &__img {
      display: block;
      max-width: 100%;
      margin-right: 30px;

      @include sm {
        margin-right: 0;
      }
    }
    &__heading {
      margin-bottom: 30px;

      @include sm {
        margin-top: 15px;
        margin-bottom: 15px;
      }
    }
    &__text {
      font-size: 18px;

      @include sm {
        font-size: 16px;
        margin-bottom: 15px;
      }
    }
    &__btm {
      background: $w;
      padding: 50px;
      box-shadow: 12px 12px 24px rgba(35, 111, 153, 0.04),
        -12px -12px 24px rgba(35, 111, 153, 0.04);

      @include lg {
        padding: 30px;
      }
      @include sm {
        padding: 15px;
        text-align: center;
      }
    }
    &__row {
      @include row(15);
    }
    &__col {
      @include col(4, 15);

      @include md {
        @include col(6, 15);
        margin-bottom: 20px;
      }
      @include xs {
        @include col(12, 15);
      }
    }
    &__value {
      @include h(3);
      margin-bottom: 5px;
      color: $bd;

      @include sm {
        margin-bottom: 0;
      }
    }
    &__label {
      font-size: 14px;
      color: $gt;
    }
  }
}

.credblocks {
  background: $w;
  padding: 70px 0;

  &-items {
    @include row(15);
  }
  &-item {
    @include col(6, 15);
    margin-bottom: 30px;
    background: $bg2;
    padding: 40px;

    @include lg {
      padding: 30px;
    }
    @include md {
      @include col(12, 15);
    }
    @include sm {
      padding: 15px 15px 25px;
      margin-bottom: 20px;
    }
    &__heading {
      @include h(2);
      margin-bottom: 5px;
    }
    &__text {
      font-size: 18px;
      color: $gt;
      margin-bottom: 40px;

      @include xs {
        font-size: 16px;
        margin-bottom: 20px;
      }
    }
    &__row {
      @include flex(lt);
      margin-bottom: 40px;

      @include xs {
        flex-wrap: wrap;
        margin-bottom: 20px;
      }
    }
    &__col {
      margin-right: 20px;

      @include xs {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
    &__value {
      @include h(3);
      color: $bb;
    }
    &__label {
      font-size: 14px;
      color: $gt;
    }
    &__btm {
      @include flex(sc);

      @include xs {
        @include flex(cc);
        flex-wrap: wrap;

        .btn--arrow {
          width: 100%;
          margin-bottom: 30px;
        }
      }
    }
  }
}

.credlists {
  margin-top: 70px;
  margin-bottom: 70px;

  &-heading {
    margin-bottom: 20px;
  }
  &-items {
    margin-bottom: 50px;

    @include xs {
      margin-bottom: 30px;
    }
  }
  &-item {
    background: $bg2;
    padding: 30px 40px;
    @include flex(sc);
    margin-bottom: 10px;
    font-size: 18px;
    min-height: 110px;

    @include lg {
      padding: 30px;
    }
    @include sm {
      padding: 15px;
      @include flex(lt);
      flex-wrap: wrap;
      font-size: 16px;
      min-height: inherit;
    }
    &__label {
      width: 50%;
      flex-shrink: 0;
      color: $gt;

      @include xs {
        width: 100%;
        margin-bottom: 10px;
      }
    }
    &__value {
      width: 50%;
      flex-shrink: 0;
      font-weight: 500;
      @include flex(lc);

      @include xs {
        width: 100%;
      }
    }
  }
  &-btm {
    @include flex(sc);

    @include xs {
      @include flex(cc);
      flex-wrap: wrap;

      .btn--arrow {
        width: 100%;
        margin-bottom: 30px;
      }
      .btn {
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 30px;
      }
    }
  }
}

.cabinet {
  &-tabs {
    .tabs__content {
      background: $w;
      min-height: 50vh;
      margin: 0 !important;
      padding: 0 !important;
    }
    .tabs__content > .tabs__pane {
      margin: 0 !important;
      padding: 0 !important;
    }
  }
  &-cat {
    margin-top: 50px;
    margin-bottom: 70px;

    &__btm {
      @include flex(cc);
    }
    &__btms {
      @include flex(lc);
    }
  }
}

.shops {
  margin-top: 50px;
  margin-bottom: 70px;
  & .my-shops {
    &__no-shops {
      font-weight: 300;
      font-size: 20px;
      line-height: 30px;
      color: #828794;
    }
    &__name-shop {
      cursor: pointer;
      &:hover > strong {
        color: $bl;
      }
    }
  }
}

.create-shop {
  .conteiner {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
    padding: 0 30px;
  }
  &__info {
    margin-top: 50px;
    margin-bottom: 70px;
  }
  &__all-info {
    margin-top: 40px;
  }
}
.main-info-shop {
  display: flex;
  align-items: center;
  &__name {
    // margin-left: 66px;
  }
}

.description-shop {
  margin-bottom: 41px;
  .form-col {
    @include col(4, 15);
    @include sm {
      width: 100%;
      margin: 0;
    }
  }
  .form-col_big {
    @include col(8, 15);
    @include sm {
      width: 100%;
      margin: 0;
    }
  }
  .form-col-12 {
    @include col(12, 10);
  }
  .form-hint-group {
    position: relative;
    display: flex;
    min-height: 25px;
    justify-content: space-between;
    align-items: flex-end;
  }
  .form-hint {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    // width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #484848;
  }
  .form-col_min {
    @include col(3, 15);
    @include xs {
      width: 100%;
      margin: 0;
    }
  }
  .form-row {
    @include row(15);
    @include xs {
      @include row(0);
      flex-direction: column;
    }
  }
}

.profile {
  margin-top: 50px;
  margin-bottom: 70px;
  display: block;
  width: 100%;

  &-top {
    @include flex(lc);
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid $brd;
    position: relative;

    @include xs {
      flex-wrap: wrap;
    }
    &__left {
      width: 150px;
      margin-right: 50px;
      flex-shrink: 0;
    }
    &__right {
      width: 100%;
      position: relative;
    }
  }
  &-ava {
    width: 150px;
    height: 150px;
    position: relative;

    &__delete {
      border: none;
      position: absolute;
      top: -10px;
      right: -10px;
      width: 30px;
      height: 30px;
      background: url(../../images/del.svg) center no-repeat;
      cursor: pointer;
      transition: 0.2s;

      &:hover {
        transform: scale(1.4);
      }
    }
    &__wrap {
      width: 150px;
      height: 150px;
      border-radius: 100%;
      overflow: hidden;
      position: relative;
    }
    &__img {
      width: 150px;
      height: 150px;
      display: block;
      border-radius: 100%;
      object-fit: cover;
    }
    &__file {
      position: absolute;
      border-radius: 100%;
      top: 0;
      left: 0;
      width: 150px;
      height: 150px;
      overflow: hidden;
      @include flex(cc);
      background: rgba(22, 50, 79, 0.5);
      // opacity: 0;
      transition: 0.2s;

      &-input {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        cursor: pointer;
        border-radius: 100%;
      }
      &-label {
        text-align: center;
        color: $w;
        font-size: 14px;
      }
    }
    &__wrap:hover &__file {
      // opacity: 1;
    }
  }
  &-name {
    @include h(2);
    color: $bd;
    margin-bottom: 10px;
  }
  &-number {
    @include flex(lc);
    color: $bd;

    &:before {
      width: 32px;
      height: 32px;
      content: "";
      margin-right: 10px;
      display: block;
      background: url(../../images/number.svg) center no-repeat;
    }
  }
  &-exit {
    position: absolute;
    top: 0px;
    right: 0;

    @include xs {
      position: relative;
      margin-top: 10px;
    }
  }
  &-mid {
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid $brd;
  }
  &-row {
    @include row(15);
  }
  &-col3 {
    @include col(3, 15);

    @include lg {
      @include col(4, 15);
    }
    @include md {
      @include col(6, 15);
    }
    @include xs {
      @include col(12, 15);
    }
  }
  &-col6 {
    @include col(6, 15);

    @include xs {
      @include col(6, 15);
    }
  }
  &-col9 {
    @include col(9, 15);

    @include lg {
      @include col(12, 15);
    }
  }
  &-btm {
    @include flex(st);

    @include xs {
      flex-wrap: wrap;
    }
    &--revers {
      @include xs {
        flex-direction: column-reverse;
      }
      .btn--delete {
        margin-top: 25px;
      }
    }
  }
  &-links {
    @include flex(lt);

    @include xs {
      flex-wrap: wrap;
      order: 2;
      margin-top: 20px;
    }
  }
  &-link {
    margin-right: 15px;
    padding-right: 15px;
    border-right: 1px solid $brd;

    @include xs {
      width: 100%;
      margin-right: 0;
      padding-left: 0;
      border: 0;
      margin-bottom: 15px;
    }

    &:last-child {
      border: 0;
    }
  }
}

.orders {
  margin-top: 50px;
  margin-bottom: 70px;
  @include row(15);

  @include md {
    @include row(10);
  }
  &-left {
    @include col(4, 15);

    @include md {
      @include col(12, 10);
    }
  }
  &-right {
    @include col(8, 15);

    @include md {
      @include col(12, 10);
    }
  }
  &-search {
    position: relative;
    margin-bottom: 20px;

    &__input {
      width: 100%;
      padding-right: 34px;
    }
    &__btn {
      position: absolute;
      top: 0;
      right: 0;
      width: 34px;
      height: 40px;
      background: transparent url(../../images/search.svg) center no-repeat !important;
      display: block;
      min-width: 34px;
      transition: 0.2s;
      opacity: 0.6;
      padding: 0 !important;

      &:hover {
        opacity: 1;
      }
    }
  }
  &-list {
    max-height: 540px;
    overflow: auto;
    border: 1px solid $brd;
    @extend %scroll;

    @include md {
      max-height: 300px;
      margin-bottom: 20px;
    }
    &__items {
    }
    &-item {
      @include flex(st);
      border-bottom: 1px solid $brd;
      padding: 10px 15px;
      margin-top: -1px;

      @include xs {
        padding: 5px 10px;
      }
      &:hover {
        background: $bg;
        border-color: $bg;
      }
      &--active {
        background: $bl !important;
        border-color: $bl !important;
      }
      &:first-child {
        margin-top: 0;
      }
      &__left {
        width: 100%;
      }
      &__right {
        text-align: right;
        flex-shrink: 0;
      }
      &__num {
        font-weight: 500;
        margin-bottom: 5px;
        color: $bb;

        .orders-list-item--active & {
          color: $w;
        }
      }
      &__desc {
        font-size: 12px;
        color: $gt;
        .orders-list-item--active & {
          color: $w;
        }
      }
      &__date {
        font-size: 12px;
        color: $b;
        margin-bottom: 5px;

        .orders-list-item--active & {
          color: $w;
        }
      }
      &__sum {
        background: $gr;
        color: $w;
        border-radius: 12px;
        padding: 1px 5px;
        text-align: center;
        font-size: 14px;
        font-weight: bold;

        .orders-list-item--active & {
          color: $bl;
          background: rgba(255, 255, 255, 0.75);
        }
      }
    }
  }
  &-name {
    line-height: 40px;
    margin-bottom: 20px;
    @extend %p20;

    strong {
      color: $bb;
    }
  }
  &-block {
    background: $w;
    padding: 30px;
    box-shadow: 12px 12px 24px rgba(35, 111, 153, 0.04),
      -12px -12px 24px rgba(35, 111, 153, 0.04);

    @include xs {
      background-color: transparent;
      padding: 0;
      box-shadow: none;
    }
  }
  &-ok,
  &-wait,
  &-cancel,
  &-phone {
    border-radius: 4px;
    display: inline-block !important;
    padding: 2px 8px;
    font-size: 14px;
    background: $gre;
    color: $w;
    margin-left: 10px;

    @include xs {
      margin-left: 0;
      margin-top: 10px;
    }
  }
  &-wait {
    background: $gr;
  }
  &-cancel {
    background: $rd;
  }
  &-phone {
    background: $bl;
  }
  .cart-item {
  }
  @include xs {
    .cart-items__name {
      span {
        display: block;
      }
    }
    .cart-item__sum {
      width: 50%;
    }
  }
}

.addprod {
  &-row {
    @include row(15);
  }
  &-col12 {
    @include col(12, 15);
  }
  &-help {
    display: flex;
    justify-content: flex-end;
    .tabs__link_help {
      padding: 10px 25px;
      &::after {
        right: 0;
      }
    }
  }
  &-left {
    @include col(7, 15);

    @include md {
      @include col(12, 15);
    }
  }
  &-right {
    @include col(5, 15);

    @include md {
      @include col(12, 15);
    }
  }
  &-image {
    display: flex;
    position: relative;
    overflow: hidden;
    height: 100%;

    @include md {
      height: 300px;
    }
    &__wrap {
      border: 1px dashed $gg;
      border-radius: 6px;
      margin-top: 20px;
      margin-bottom: 20px;
      width: 100%;
      position: relative;
      overflow: hidden;
      @include flex(cc);
    }
    &__img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    &__file {
      // z-index: 2;
      // position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      opacity: 0;
      // cursor: pointer;
    }
    &__label {
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      z-index: 2;
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
      // width: 180px;
      // height: 50px;
      background: $bl;
      color: $w;
      padding: 14px 30px;
      border-radius: 6px;
      cursor: pointer;
    }
    &__filetext {
      max-width: 280px;
      margin-top: -70px;
      padding-top: 60px;
      text-align: center;
      position: relative;
      font-size: 14px;
      color: $gt;

      &:before {
        width: 100%;
        height: 60px;
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        background: url(../../images/jpg.svg) center no-repeat;
      }
    }
  }
  &-btm {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid $brd;
    @include flex(sc);
    flex-wrap: wrap;
  }
  &-cancel {
    @include xs {
      display: none;
    }
  }
  &-save {
    @include xs {
      width: 100%;
    }
  }
}

.search {
  &-form {
    padding: 50px 0;
    background: $bg;

    @include sm {
      padding: 20px 0;
    }
    &__form {
      position: relative;
    }
    &__input {
      width: 100%;
      padding-right: 34px;
      background: transparent;
      border: 0 !important;
      border-bottom: 2px solid $gg !important;
      border-radius: 0px;
      height: 50px;
      font-size: 26px;

      &:focus {
        border: 0 !important;
        border-bottom: 2px solid $gg !important;
      }
      @include md {
        font-size: 18px;
      }
    }
    &__btn {
      position: absolute;
      top: 0;
      right: 0;
      width: 34px;
      height: 50px;
      background: transparent url(../../images/search.svg) center no-repeat !important;
      display: block;
      min-width: 34px;
      transition: 0.2s;
      opacity: 0.6;
      padding: 0 !important;

      &:hover {
        opacity: 1;
      }
    }
  }
  &__service_btn {
    width: 38px;
    height: 38px;
    left: 0;
  }
  &__service_input {
    height: 38px;
    padding-left: 38px;
  }
  &-heading {
    span {
      color: $gt;
    }
  }
  &-results {
    margin-top: 30px;
    margin-bottom: 70px;

    &__item {
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid $brd;
    }
    &__name {
      @extend %p20;
      margin-bottom: 10px;
      font-weight: 500;
    }
    &__text {
      padding-left: 22px;
      color: $gt;
    }
  }
}

.p404 {
  text-align: center;
  @include flex(cc);

  .container {
    @include flex(cc);
    @include flexcol(n);
  }
  h3 {
    margin: 30px 0;
  }
}

.credlists {
  margin-top: 70px;
  margin-bottom: 70px;

  &-heading {
    margin-bottom: 20px;
  }
  &-items {
    margin-bottom: 50px;

    @include xs {
      margin-bottom: 30px;
    }
  }
  &-item {
    background: $bg2;
    padding: 30px 40px;
    @include flex(sc);
    margin-bottom: 10px;
    font-size: 18px;
    min-height: 110px;

    @include lg {
      padding: 30px;
    }
    @include sm {
      padding: 15px;
      @include flex(lt);
      flex-wrap: wrap;
      font-size: 16px;
      min-height: inherit;
    }
    &__label {
      width: 50%;
      flex-shrink: 0;
      color: $gt;

      @include xs {
        width: 100%;
        margin-bottom: 10px;
      }
    }
    &__value {
      width: 50%;
      flex-shrink: 0;
      font-weight: 500;
      @include flex(lc);

      @include xs {
        width: 100%;
      }
    }
  }
  &-btm {
    @include flex(sc);

    @include xs {
      @include flex(cc);
      flex-wrap: wrap;

      .btn--arrow {
        width: 100%;
        margin-bottom: 30px;
      }
    }
  }
}

.credlists {
  background: $w;
  margin: 0;
  padding: 20px 0;
}

.credcalc {
  background: $w;
  padding: 20px 0;

  &__tabs {
    display: flex;
    border-bottom: 1px solid #b5cfe8;
    height: 70px;
    margin-bottom: 20px;
  }

  .tab {
    margin-right: 20px;
    font-weight: 500;
    color: #838aa2;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;

    &:hover {
      color: rgb(36, 112, 212);
    }
  }

  .active {
    border-bottom: 3px solid #0a369d;
    color: #000;
    padding-top: 3px;
  }

  .tab-content {
    display: none;
  }

  .show {
    display: block;
  }

  .range-box {
    height: 70px;
    background: $bg2;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  &-row {
    @include row(15);
    align-items: stretch;
  }
  &-form {
    @include col(6, 15);

    @include md {
      @include col(12, 15);
    }
  }
  &-results {
    @include col(6, 15);

    @include md {
      @include col(12, 15);
    }
  }
  .noUi-horizontal .noUi-handle {
    right: -25px;
    top: -11px;
  }
  .noUi-handle {
    background: $bl;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    box-shadow: 0px 8px 16px rgba(91, 142, 250, 0.2);
    border: 0;
    outline: none;

    &:before,
    &:after {
      display: none;
    }
  }
  .noUi-connect {
    background: $bl;
  }
  .noUi-target {
    background: transparent;
    border-radius: 0;
    border: 0;
    box-shadow: none;
  }
  .noUi-base,
  .noUi-connects {
    height: 3px;
  }
  .noUi-marker-large {
    display: none;
  }
  .noUi-pips-horizontal {
    padding: 0;
    height: 50px;
    top: 10px;
    color: #838aa2;
    font-size: 14px;

    .noUi-value:nth-child(4) {
      transform: translateX(-100%);
    }
  }
  .noUi-value-horizontal {
    transform: translate(0%);
  }
  .form-label {
    font-size: 14px;
    color: #838aa2;
    margin-bottom: 5px;
  }
  .form-group {
    margin-bottom: 30px;
  }
  .select2-selection {
    background: #f6f7f8;
    font-size: 20px;
    height: 72px;
    border: 0;
    font-weight: normal;
    color: #16324f;
    padding-left: 10px;
  }
  .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    line-height: 72px;
  }
  .select2-search {
    display: none !important;
  }
  .min-max {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 14px;
    color: #838aa2;
  }

  &-results {
    height: auto;

    &__block {
      border-radius: 12px;
      border: 1px solid $gr;
      padding: 30px;
      margin-top: 25px;

      @include sm {
        padding: 15px;
      }
    }
    &__heading {
      @include h(2);
      margin-bottom: 20px;
    }
    &__row {
      @include row(15);
    }
    &__sums {
      @include col(7, 15);

      @include xs {
        @include col(12, 15);
        margin-bottom: 15px;
      }
    }
    &__label {
      font-size: 14px;
      margin-bottom: 10px;

      @include xs {
        margin-bottom: 3px;
      }
    }
    &__sum {
      color: $bl;
      font-size: 58px;
      line-height: 64px;
      font-weight: 500;

      @include lg {
        font-size: 40px;
        line-height: 40px;
      }
      @include xs {
      }
    }
    &__percs {
      @include col(5, 15);

      @include xs {
        @include col(12, 15);
      }
    }
    &__perc {
      font-size: 58px;
      line-height: 64px;

      @include lg {
        font-size: 40px;
        line-height: 40px;
      }
      @include xs {
      }
    }
    &__bottom {
      margin-top: 40px;
    }
    &__info {
      margin-top: 15px;
      font-size: 14px;
    }
  }
}

.partner {
  padding-bottom: 60px;

  &-heading {
    text-align: center;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    max-width: 740px;
  }
  &-block {
    margin-bottom: 20px;
    background: $w;
    padding: 60px;

    @include lg {
      padding: 40px;
    }
    @include xs {
      padding: 25px 15px;
      margin-left: -15px;
      margin-right: -15px;
    }
    &__row {
      @include flex(lt);

      @include md {
        flex-wrap: wrap;
      }
    }
    &__left {
      width: 320px;
      flex-shrink: 0;

      @include lg {
        width: 220px;
      }
      @include md {
        width: 100%;
        margin-bottom: 20px;
      }
      @include xs {
        margin-bottom: 5px;
      }
    }
    &__right {
      width: 100%;
    }
    &__heading {
      font-size: 32px;
      font-weight: bold;
      color: $gt;

      @include md {
        font-size: 28px;
      }
    }
    &__logo {
      margin-top: 40px;
      width: 60%;
    }
    &__text {
      margin-bottom: 20px;

      h3 {
        font-size: 22px;
        margin-bottom: 30px;
        line-height: 1.5;

        @include md {
          font-size: 20px;
          margin-bottom: 20px;
        }
        @include xs {
          font-size: 18px;
          line-height: 1.4;
        }
        span {
          color: $bb;
        }
      }
      p {
        line-height: 1.6;
        margin-bottom: 30px;

        @include md {
          margin-bottom: 20px;
          line-height: 1.5;
        }
      }
      hr {
        max-width: 100px;
        margin-left: 0;
      }
      p.blue {
        font-size: 20px;
        font-weight: 300;
        color: $bb;

        @include xs {
          font-size: 18px;
        }
      }
      blockquote {
        background: #f5f8ff;
        color: $bb;
        font-size: 20px;
        font-weight: 500;
        padding: 50px 60px;
        margin: 50px 0;
        line-height: 1.5;
        position: relative;

        @include md {
          padding: 40px;
        }
        @include sm {
          padding: 40px 25px;
          font-size: 18px;
          line-height: 1.4;
        }
        &::before {
          width: 54px;
          height: 54px;
          background: url(../../images/quotes.svg) center no-repeat;
          position: absolute;
          left: 20px;
          top: -20px;
          content: "";
        }
        &::after {
          width: 54px;
          height: 54px;
          background: url(../../images/quotes.svg) center no-repeat;
          position: absolute;
          right: 20px;
          bottom: -20px;
          content: "";
          transform: rotate(180deg);
        }
      }
    }
    &__full {
      margin-top: 50px;
    }
  }
  &-nums {
    @include flex(st);
    margin-bottom: 40px;

    @include xs {
      flex-wrap: wrap;
    }
    &__item {
      padding-right: 20px;

      @include xs {
        width: 100%;
        text-align: center;
        margin-top: 20px;
      }
    }
    &__count {
      font-size: 48px;
      font-weight: bold;
      color: $bb;
      line-height: 48px;

      @include md {
        font-size: 42px;
        line-height: 42px;
      }
    }
  }
  &-btns {
    @include xs {
      @include flex(cc);
      @include flexcol(n);
      margin-top: 15px;
    }
    .btn {
      margin-right: 40px;

      @include md {
        margin-right: 30px;
      }
      @include xs {
        margin-right: 0;
        margin-bottom: 30px;
      }
    }
    &__be {
      padding: 20px 50px;
      font-size: 18px;

      @include md {
        font-size: 16px;
      }
    }
    &__doc {
      font-size: 18px;

      @include md {
        font-size: 16px;
      }
    }
  }
}

.dropdown-item {
  display: flex;
  margin-top: 7px;
  padding: 7px;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  &:hover {
    color: $bb;
  }
  &:hover > .dropdown-item__icon {
    stroke: $bb;
  }
  &__icon {
    display: block;
    margin-right: 12.5px;
    margin-top: 1px;
    stroke: #16324f;
  }
}

.dropdown-edit-menu {
  position: absolute;
  z-index: 900;
  bottom: 150%;
  background: $w;
  text-align: center;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 7px;
}
.open-edit {
  display: block;
  &__wrapper {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    z-index: 300;
    background: transparent;
  }
}
.close-edit {
  display: none;
  &__wrapper {
    display: none;
  }
}

.message {
  &-block {
    width: 100%;
    text-align: center;
  }
  &-text {
    margin-bottom: 15px;
    font-size: 20px;
  }
}

.captcha {
  display: flex;
  justify-content: center;
  align-items: center;
}

.captcha__input {
  width: 100%;
  margin-left: 15px;
}

.captcha__image {
  margin-bottom: 5px;
}

.captcha__hover {
  cursor: pointer;
}

.btn__services-add {
  // position: relative;
  width: 100%;
  max-height: 56px;
  min-height: 56px;
  margin: 0;
  .catalog-item__add {
    padding: 0;
    &::before {
      left: 50%;
      top: 50%;
      width: 29px;
      height: 29px;
      border-radius: 50%;
      transform: translate(-73px, -50%);
    }
  }
}

.shopage {
  padding-bottom: 80px;

  &-row {
    @include row(15);
    margin-bottom: 50px;
  }
  &-left {
    @include col(7, 15);

    @include md {
      @include col(12, 15);
      margin-bottom: 30px;
    }
    @include sm {
      margin-bottom: 15px;
    }
  }
  &-right {
    @include col(5, 15);

    @include md {
      @include col(12, 15);
    }
  }
  &-gallery {
    position: relative;
    .control-dots,
    .thumbs-wrapper .control-arrow,
    .carousel-status,
    .carousel .slider-wrapper.axis-horizontal .slider .slide:empty {
      display: none !important;
      width: 0 !important;
      height: 0 !important;
      margin: 0 !important;
      padding: 0 !important;
    }
    .thumbs {
      padding: 0;
      margin: 0;
    }
    .slider {
      height: 100%;
    }
    &__big,
    .carousel.carousel-slider,
    .slider-wrapper {
      width: 100%;
      height: 322px;
      position: relative;
    }
    &__img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .carousel .control-disabled.control-arrow {
      opacity: 0.2 !important;
      display: block;
    }
    &__prev,
    &__next,
    .control-arrow {
      width: 50px;
      height: 50px;
      position: absolute;
      top: auto !important;
      bottom: 0 !important;
      right: 50px !important;
      left: auto !important;
      background: $bg !important;
      cursor: pointer;
      z-index: 2;
      transform: scaleX(-1);
      border: none !important;

      &::before {
        display: none !important;
      }

      &:after {
        background: url(../../images/bread.svg) center no-repeat;
        background-size: 8px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        transition: 0.3s;
        border: none !important;
      }
      &:hover:after {
        transform: scale(1.15);
      }

      &:disabled {
        cursor: default;
        opacity: 0.5;
        display: block;
      }
    }
    &__next,
    .control-arrow.control-next {
      right: 0 !important;
      transform: scaleX(1);
    }
    &__thumbs,
    .thumbs-wrapper {
      margin: 0 !important;
      margin-top: 14px !important;
      @include row(7);

      @include sm {
        @include row(3);
        margin-top: 6px;
      }
    }
    &__thumb,
    .thumb {
      @include col(2.4, 7);
      margin-bottom: 7px;
      transition: 0.3s;
      opacity: 0.8;
      height: 58px;
      border: none;
      padding: 0;
      &:first-child {
        margin-left: 0;
      }
      &.selected,
      &:hover {
        border: none !important;
        opacity: 1;
      }
      .shopage-gallery__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @include sm {
        @include col(3, 3);
        margin-bottom: 6px;
      }
      &.active,
      .slick-active,
      &:hover {
        opacity: 1;
      }
    }
  }
  &-title {
    @include h(3);
    margin-bottom: 20px;
  }
  &-minidesc {
    color: $gt;
    margin-bottom: 30px;
  }
  &-btns {
    margin-bottom: 60px;
  }
  &-social {
    @include flex(lc);
    &__item {
      margin-right: 20px;
      display: block;
      transition: 0.3s;
      user-select: none;
      &:hover {
        transform: scale(1.15);
      }

      &_disabled {
        cursor: not-allowed;
        opacity: 0.5;
        text-decoration: none;
        pointer-events: none;
      }
    }
  }
  &-share {
    position: relative;
    @include flex(rt);

    &__wrap {
      @include flex(lc);
    }
    &__label {
      color: $gt;
      margin-right: 15px;
    }
    &__item {
      margin-right: 10px;
      display: block;
      transition: 0.3s;

      &:hover {
        transform: scale(1.15);
      }
    }
    &__save-link {
      position: absolute;
      content: "";
      right: 15px;
      bottom: -20px;
      font-size: 12px;
      color: #838aa2;
      transition: 0.2s;
    }
  }
  &-rating {
    @include flex(lc);
    margin-top: 10px;

    &__stars {
      @include flex(lc);
    }
    &__star {
      width: 16px;
      display: block;
      margin-right: 5px;
    }
    &__link {
      margin-left: 10px;
    }
  }
  &-reviews {
    &__row {
      @include row(15);
    }
    &__left {
      @include col(7, 15);

      @include md {
        @include col(12, 15);
      }
    }
    &__right {
      @include col(5, 15);
      position: relative;

      @include md {
        @include col(12, 15);
      }
    }
    &__reply {
      width: 100%;
      margin-top: 15px;
      background: $bg2;
      border-radius: 8px;
      padding: 10px;
    }
    &__form {
      width: 100%;
      background: $bg2;
      border-radius: 8px;
      padding: 30px;

      @include sm {
        padding: 25px 15px;
        margin-left: -15px;
        margin-right: -15px;
        width: auto;
      }
      &-heading {
        @include h(3);
        margin-bottom: 15px;
      }
    }
    &__heading {
      @include h(3);
      margin-bottom: 30px;
    }
    &__empty {
      margin-bottom: 40px;
    }
    &-item {
      width: 100%;
      margin-bottom: 50px;

      &__top {
        @include flex(sc);

        &-left {
          @include flex(lc);

          @include sm {
            flex-wrap: wrap;
          }
        }
      }
      &__name {
        font-weight: bold;
        margin-right: 20px;

        @include sm {
          width: 100%;
          margin-bottom: 6px;
        }
      }
      &__date {
        color: $gt;
        font-size: 14px;
        flex-shrink: 0;
      }
      &__text {
        margin-top: 10px;
        color: $texti;
      }
      &__reply {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
