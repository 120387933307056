@import "../../../styles/global/variables.scss";

.home-slider {
    .carousel.carousel-slider .control-arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        transition: none;
        opacity: 1;
        top: 50% !important;
        background: $b3 !important;
        color: #fff;
        bottom: initial !important;
        height: 50px;
        width: 50px;
        transform: translate(0, -50%);
    }
    .carousel.carousel-slider .slider-img {
        max-height: 235px;
    }

    .carousel.carousel-slider .control-next.control-arrow:before {
        content: "";
        border: solid $bl;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 4px;
        transform: rotate(-45deg);
    }

    .carousel.carousel-slider .control-prev.control-arrow:before {
        content: "";
        border: solid $bl;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 4px;
        transform: rotate(135deg);
    }
}
